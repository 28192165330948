function stringToDate(str) {
  if (!str || str.length === 0) {
    return null;
  }
  return new Date(str);
}

function dateToString(date) {
  return date.toISOString();
}

function dateToUSFormat(date, opts) {
  if (!date) {
    return null;
  }
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    ...opts,
  };
  return date.toLocaleString("en-US", options);
}

export const convertDate = {
  stringToDate,
  dateToString,
  dateToUSFormat,
};

export function dateToLocalIgnoringOffset(date) {
  if (!date) {
    return null;
  }
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  return new Date(year, month, day, hour, minute, seconds);
}

export function debouncer(callback, wait) {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}

export function scrollToSection(sectionId, options) {
  const element = document.getElementById(sectionId);
  if (!element) {
    console.error(`Cannot find element with id ${sectionId}`);
    return;
  }
  // We could use scrollIntoViewIfNeeded when is fully supported in all the browsers
  element.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "center",
    ...options,
  });
}

// This function is used in sort functions
export function compareTwoValues(a, b) {
  if (typeof a === "number" && typeof b === "number") {
    return a - b;
  }
  if (typeof a === "boolean" && typeof b === "boolean") {
    return a === b ? 0 : a ? -1 : 1;
  }
  return a.localeCompare(b, "en", { sensitivity: "base" });
}

export const projectVersions = {
  previewMode: "Preview Mode",
  premium: "premium",
};

export const apiPrefix = `${
  window.env && window.env.pmiApiUrl
    ? window.env.pmiApiUrl
    : "http://localhost:8080"
}/api/pmi`;

export const deviceApiPrefix = `${
  window.env && window.env.microservicesApiUrl
    ? window.env.microservicesApiUrl
    : "http://localhost:8090"
}/api/pmi_device`;

export const userApiPrefix = `${
  window.env && window.env.microservicesApiUrl
    ? window.env.microservicesApiUrl
    : "http://localhost:8094"
}/api/pmi_user`;

export const projectApiPrefix = `${
  window.env && window.env.microservicesApiUrl
    ? window.env.microservicesApiUrl
    : "http://localhost:8088"
}/api/pmi_project`;

export function capitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
