import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  Chip,
  Divider,
  Link,
  Stack,
  Switch,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import VisitsDrawer from "../VisitsDrawer";
import InfoIcon from "@mui/icons-material/Info";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import theme from "../../../../styles/theme";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import {
  sortPatientVisits,
  visitsSortByEnum as sortByEnum,
} from "../../../../models/patient";
import { useFetch } from "../../../../services/hooks/useFetch";
import { userApiPrefix } from "../../../../utils/main";
import { useFusionAuth } from "@fusionauth/react-sdk";
import { projectsPrefix } from "../../../../services/ProjectsServices";
import { VisitInfoSkeleton } from "./skeletons/VisitsSkeleton";
import { CurrentPatient } from "../../ProjectDetails";

export default function VisitsInfo({
  patient,
  updatePatient,
  updatePatientList,
  encounters,
  imdrfEnabled,
}) {
  const { setIsEncountersUpdated } = useContext(CurrentPatient);
  const [drawer, setDrawer] = useState(false);
  const [visitToLoad, setVisitToLoad] = useState({});
  const [localEncounters, setLocalEncounters] = useState(encounters);
  const [sortBy, setSortBy] = useState(sortByEnum.title);
  const [sort, setSort] = useState("asc");
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const { update: updateSettings } = useFetch();
  const { refreshToken } = useFusionAuth();
  const settings = JSON.parse(localStorage.getItem("settings"));
  const [hide, setHide] = useState(
    settings.hasOwnProperty(projectId) &&
      settings[projectId].hasOwnProperty("FILTER_EMPTY_VISITS")
      ? settings[projectId].FILTER_EMPTY_VISITS === "TRUE"
      : false
  );
  const { isLoading, fetch: fetchEncounters } = useFetch();
  const visitsSorted = useMemo(() => {
    if (!localEncounters) {
      return [];
    }
    const dataUnsorted = [...localEncounters];
    if (sort === "" || sortBy === "") {
      return dataUnsorted;
    }
    return sortPatientVisits(sort, sortBy, dataUnsorted);
  }, [localEncounters, sort, sortBy]);

  function openDrawer(visit) {
    setVisitToLoad(visit.id);
    setDrawer(true);
  }

  function toggleDrawer() {
    setDrawer(false);
  }

  function sortByTitle() {
    if (sortBy !== sortByEnum.title || sort === "asc") {
      setSort("desc");
    } else {
      setSort("asc");
    }
    setSortBy(sortByEnum.title);
  }

  function sortByPriority() {
    if (sortBy !== sortByEnum.priority || sort === "desc") {
      setSort("asc");
    } else {
      setSort("desc");
    }
    setSortBy(sortByEnum.priority);
  }

  function sortByRelated() {
    if (sortBy !== sortByEnum.related || sort === "asc") {
      setSort("desc");
    } else {
      setSort("asc");
    }
    setSortBy(sortByEnum.related);
  }

  useEffect(() => {
    setLocalEncounters(encounters);
  }, [encounters]);

  const handleVisitListUpdate = useCallback(
    (data) => {
      setLocalEncounters(data);
      updatePatient({ encounters: data });
      setIsEncountersUpdated(true);
    },
    [updatePatient, setIsEncountersUpdated]
  );

  const handleSingleVisitUpdate = useCallback(
    (data) => {
      const updatedList = localEncounters.map((item) => {
        return item.id === data.id
          ? {
              ...item,
              ...data,
            }
          : item;
      });
      updatePatient({ encounters: updatedList });
      setLocalEncounters(updatedList);
    },
    [localEncounters, updatePatient]
  );

  async function handleSwitch() {
    const value = hide ? "FALSE" : "TRUE";
    const payload = {
      projectId: projectId,
      name: "FILTER_EMPTY_VISITS",
      value: value,
    };
    await updateSettings(`${userApiPrefix}/user/setting`, payload, "PUT");
    localStorage.setItem(
      "settings",
      JSON.stringify({
        ...settings,
        [projectId]: {
          FILTER_EMPTY_VISITS: value,
        },
      })
    );
    void refreshToken();

    fetchEncounters(
      `${projectsPrefix}/${projectId}/patients/${patient.id}/encounters/`,
      (data) => {
        handleVisitListUpdate(data);
        return { ...data };
      }
    );
    setHide(!hide);
  }

  return (
    <>
      {drawer && (
        <VisitsDrawer
          toggleDrawer={() => toggleDrawer()}
          patientId={patient.id}
          visitId={visitToLoad}
          updatedList={handleSingleVisitUpdate}
          updatePatientList={updatePatientList}
          imdrfEnabled={imdrfEnabled}
        />
      )}
      <div className="visits-toggle">
        <Switch id="filterEmptyVisits" checked={hide} onClick={handleSwitch} />
        <Typography variant="body1">Always Hide Empty Visits</Typography>
        <Tooltip
          title={
            <div>
              When toggled on, this will hide any visits that do not contain any
              of the following data:
              <ul>
                <li>Clinical Notes</li>
                <li>Diagnosis Codes</li>
                <li>Procedure Codes</li>
              </ul>
            </div>
          }
          placement="top-end"
        >
          <InfoIcon size="small" sx={{ color: theme.palette.info.main }} />
        </Tooltip>
      </div>
      <div className="visits-header">
        <div className="visits-row-date">
          <TableSortLabel
            active={sortBy === sortByEnum.title}
            direction={sort}
            onClick={sortByTitle}
          >
            <Typography>Date </Typography>
          </TableSortLabel>
        </div>
        <div className="visits-row">
          <Divider orientation="vertical" flexItem />
          <TableSortLabel
            active={sortBy === sortByEnum.priority}
            direction={sort}
            onClick={sortByPriority}
          >
            <Typography className="left-divider">Priority</Typography>
          </TableSortLabel>
          <Tooltip
            title="Visits with a priority icon may include evidence of an adverse event, clinical or performance failure as identified by the 3Aware system."
            placement="top-end"
          >
            <InfoIcon size="small" sx={{ color: theme.palette.info.main }} />
          </Tooltip>
          <Divider orientation="vertical" flexItem className="right-divider" />
        </div>
        <div className="visits-row">
          <TableSortLabel
            active={sortBy === sortByEnum.related}
            direction={sort}
            onClick={sortByRelated}
          >
            <Typography className="left-divider">Related </Typography>
          </TableSortLabel>
        </div>
      </div>
      <Divider />
      <Stack>
        {isLoading
          ? visitsSorted.map((_, index) => {
              return <VisitInfoSkeleton key={index} />;
            })
          : visitsSorted.map((visit) => (
              // Added diffDays to prevent the duplicate child console errors for now
              // TODO: Figure out a better solution to the multiple device event issue
              <div key={visit.id + visit.diffDays}>
                <div className={"visit-item"}>
                  <div className={"visit-data"}>
                    {visit.indexProcedure === false && (
                      <Link
                        className="post-day-index"
                        component="button"
                        variant="subtitle2"
                        onClick={() => {
                          openDrawer(visit);
                        }}
                      >
                        {visit.diffDays} Days Post Index Procedure
                      </Link>
                    )}
                    {visit.indexProcedure === true && (
                      <Link
                        className="index-procedure"
                        component="button"
                        variant="subtitle2"
                        onClick={() => {
                          openDrawer(visit);
                        }}
                      >
                        Index Procedure
                      </Link>
                    )}
                    {visit.encounterType && visit.encounterNumber && (
                      <>
                        <div className={"visit-type"}>
                          <Typography
                            className={"label"}
                            sx={{ color: theme.palette.text.secondary }}
                          >
                            {"Visit ID: "}
                          </Typography>
                          <Typography
                            variant={"caption"}
                            sx={{ color: theme.palette.text.secondary }}
                          >
                            {visit.encounterNumber}
                          </Typography>
                        </div>
                        <div className={"visit-type"}>
                          <Typography
                            className={"label"}
                            sx={{ color: theme.palette.text.secondary }}
                          >
                            {"Type: "}
                          </Typography>
                          <Typography
                            variant={"caption"}
                            sx={{ color: theme.palette.text.secondary }}
                          >
                            {visit.encounterType}
                          </Typography>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="visit-icon-box">
                    {visit && visit.priority > 0 && (
                      <OfflineBoltOutlinedIcon
                        sx={{ color: theme.palette.info.main }}
                      />
                    )}
                  </div>
                  <div className={"visit-actions"}>
                    {visit.isRelated === false && (
                      <Chip
                        label="Unrelated"
                        size="small"
                        sx={{ borderRadius: 3 }}
                        onDelete={() => {}}
                        deleteIcon={
                          <Tooltip title={visit.reason}>
                            <StickyNote2OutlinedIcon
                              fontSize="small"
                              style={{ color: theme.palette.action.active }}
                            />
                          </Tooltip>
                        }
                      />
                    )}
                  </div>
                </div>
                <Divider />
              </div>
            ))}
      </Stack>
    </>
  );
}
