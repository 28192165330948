import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  Popover,
  CircularProgress,
  Stack,
} from "@mui/material";
import { projectsPrefix } from "../../../../services/ProjectsServices";
import AutoCompleteInput from "../AutoCompleteInput";
import { useSearchParams } from "react-router-dom";
import { useFetch } from "../../../../services/hooks/useFetch";
import { formatPatient } from "../../../../models/patient";

const categoryOptions = ["Clinical Benefit", "Performance", "Adverse Events"];

export default function CategorizeModal({
  anchorEl,
  open,
  onClose,
  patientId,
  selected,
  updateList,
}) {
  const [category, setCategory] = useState(selected?.category || "");
  const [subCategory, setSubCategory] = useState(selected?.subcategory || "");
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [reason, setReason] = useState(selected?.reason || "");
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const [deleting, setDeleting] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const {
    fetch: fetchAdverseSubCategories,
    data: adverseSubCategories,
    isLoading: adverseSubCategoriesLoading,
  } = useFetch();
  const {
    update: updateCategorization,
    delete: deleteCategorization,
    isLoading: patientCategorizationLoading,
    data: patientCategorization,
  } = useFetch();

  const updateSubCategoryOptions = useCallback(
    (categoryName) => {
      if (
        categoryName === "Clinical Benefit" ||
        categoryName === "Performance"
      ) {
        setSubCategoryOptions(["Success", "Failure"]);
      }
      if (categoryName === "Adverse Events") {
        fetchAdverseSubCategories(
          `${projectsPrefix}/${projectId}/adverse?search=`
        );
      }
    },
    [projectId, fetchAdverseSubCategories]
  );

  useEffect(() => {
    if (!adverseSubCategoriesLoading && !!adverseSubCategories) {
      setSubCategoryOptions(adverseSubCategories);
    }
  }, [adverseSubCategories, adverseSubCategoriesLoading]);

  function handleCategoryChange(event) {
    setCategory(event.target.value);
    setSubCategory("");
    updateSubCategoryOptions(event.target.value);
  }

  function handleReasonChange(event) {
    setReason(event.target.value);
  }

  function handleDeleteReasonChange(event) {
    setDeleteReason(event.target.value);
  }

  const handleClose = useCallback(() => {
    setSubCategory("");
    setCategory("");
    setReason("");
    setSubCategoryOptions([]);
    onClose();
  }, [setSubCategory, setCategory, setReason, setSubCategoryOptions, onClose]);

  function handleChangeSub(event) {
    setSubCategory(event.target.value);
  }

  function handleBody() {
    if (category === "Clinical Benefit") {
      return {
        reason: reason,
        clinicalBenefit: subCategory === "Success",
      };
    }
    if (category === "Performance") {
      return {
        reason: reason,
        performance: subCategory === "Success",
      };
    }
    return {
      reason: reason,
      subcategory: subCategory,
    };
  }

  function onSubmit(event) {
    event.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const newData = handleBody();
    if (newData.reason.trim() === "") {
      setLoading(false);
      return;
    }
    if (!selected) {
      updateCategorization(
        `${projectsPrefix}/${projectId}/patients/${patientId}/categorize`,
        newData,
        "PUT"
      );
      return;
    }
    let id = selected.id;
    if (selected.category.toLowerCase() === "adverse events") {
      id = selected.adverseEventId;
    }
    updateCategorization(
      `${projectsPrefix}/${projectId}/patients/${patientId}/categorize/${id}`,
      newData,
      "PUT"
    );
  }

  useEffect(() => {
    if (!patientCategorizationLoading && !!patientCategorization.id) {
      setLoading(false);
      updateList(formatPatient(patientCategorization));
      handleClose();
    }
  }, [
    patientCategorizationLoading,
    patientCategorization,
    updateList,
    handleClose,
  ]);

  function deleteAndClose(projectId, patientId, id, isAdverse, payload) {
    deleteCategorization(
      `${projectsPrefix}/${projectId}/patients/${patientId}/categorize/${id}?isAdverseEvent=${isAdverse}`,
      payload
    );
  }

  function onDelete(event) {
    event.preventDefault();
    setLoading(true);

    const payload = {
      reason: deleteReason,
    };
    if (
      selected.category.toLowerCase() === "performance" ||
      selected.category.toLowerCase() === "clinical benefit"
    ) {
      //need to put payload back once the textfield is back
      deleteAndClose(projectId, patientId, selected.id, false, payload);
      return;
    }
    if (selected.category.toLowerCase() === "adverse events") {
      deleteAndClose(
        projectId,
        patientId,
        selected.adverseEventId,
        true,
        payload
      );
    }
  }

  useEffect(() => {
    if (!selected) {
      return;
    }
    updateSubCategoryOptions(selected.category);
  }, [selected, updateSubCategoryOptions]);

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ height: 586 }}
      >
        <Stack direction={"column"} spacing={1.5} className="categorize-modal">
          <Typography className="categorize-modal-title" variant="h6">
            Categorize Patient
          </Typography>
          <Stack direction={"row"} className="categorize-dropdown">
            <FormControl size="small" fullWidth>
              <InputLabel>Select Category</InputLabel>
              <Select
                label="Select Category"
                value={category}
                onChange={handleCategoryChange}
                disabled={!!selected || deleting}
              >
                {categoryOptions.map((category) => {
                  return (
                    <MenuItem value={category} key={category}>
                      {category}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {category === "Adverse Events" && (
              <AutoCompleteInput
                label="Sub-Category"
                state={subCategory}
                setState={setSubCategory}
                options={subCategoryOptions}
                deleting={deleting}
              />
            )}
            {category !== "Adverse Events" && (
              <FormControl fullWidth size="small">
                <InputLabel>Sub-Category</InputLabel>
                <Select
                  value={subCategory}
                  size={"small"}
                  label="Sub-Category"
                  onChange={handleChangeSub}
                  disabled={category === "" || deleting}
                >
                  {subCategoryOptions.map((category) => {
                    return (
                      <MenuItem value={category} key={category}>
                        {category}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </Stack>
          <TextField
            className="categorize-modal-textfield"
            label="Enter Note/Reason for Categorization"
            fullWidth
            multiline
            rows={8}
            value={reason}
            onChange={handleReasonChange}
            disabled={deleting}
          />
          {deleting && (
            <TextField
              className="categorize-modal-delete-textfield"
              label="Enter Note/Reason for Deleting"
              fullWidth
              maxRows={8}
              multiline
              value={deleteReason}
              onChange={handleDeleteReasonChange}
              placeholder="Please enter a reason for deleting this categorization."
            />
          )}
          {!deleting && (
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent={"space-between"}
              width={"100%"}
            >
              {category !== "" && !!selected && (
                <Button
                  onClick={() => setDeleting(true)}
                  color={"error"}
                  variant={"contained"}
                  size={"small"}
                  endIcon={loading && <CircularProgress size="1.5rem" />}
                  disabled={loading}
                >
                  Delete
                </Button>
              )}
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"flex-end"}
                width={"100%"}
              >
                <Button
                  onClick={handleClose}
                  variant={"outlined"}
                  size={"small"}
                >
                  Cancel
                </Button>
                <Button
                  variant={"contained"}
                  onClick={onSubmit}
                  size={"small"}
                  endIcon={loading && <CircularProgress size="1.5rem" />}
                  disabled={
                    reason.trim() === "" || subCategory === "" || loading
                  }
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          )}
          {deleting && (
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent={"flex-end"}
              width={"100%"}
            >
              <Button
                onClick={() => setDeleting(false)}
                variant={"outlined"}
                size={"small"}
              >
                Back
              </Button>
              <Button
                variant={"contained"}
                color={"error"}
                onClick={(e) => onDelete(e)}
                size={"small"}
                endIcon={loading && <CircularProgress size="1.5rem" />}
                disabled={deleteReason.trim() === "" || loading}
              >
                Delete
              </Button>
            </Stack>
          )}
        </Stack>
      </Popover>
    </>
  );
}
