import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import { projectsPrefix } from "../../../services/ProjectsServices";
import AutoCompleteInput from "./AutoCompleteInput";
import { useSearchParams } from "react-router-dom";
import { useFetch } from "../../../services/hooks/useFetch";

export default function IndicationForUseModal({
  editData,
  onClose,
  onSave,
  loading,
  setLoading,
}) {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const [indicationText, setIndicationText] = useState(
    editData?.indicationForUse || ""
  );
  const [reason, setReason] = useState(editData?.reason || "");
  const { fetch, data: options } = useFetch();

  function onReasonChange(event) {
    setReason(event.target.value);
  }

  function onSubmit(event) {
    event.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    if (!indicationText || !reason) {
      setLoading(false);
      return;
    }
    const newData = {
      indicationForUse: indicationText,
      reason,
    };
    onSave(newData);
  }

  useEffect(() => {
    fetch(`${projectsPrefix}/${projectId}/indications`);
  }, [projectId, fetch]);

  return (
    <Modal className={"indication-of-use-modal"} open={true} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <Card classes={{ root: "card" }}>
          <CardHeader
            title={<Typography variant={"h5"}>Indication for Use</Typography>}
          />
          <CardContent>
            <AutoCompleteInput
              label="Indication for Use"
              state={indicationText}
              setState={setIndicationText}
              options={options}
            />
            <TextField
              className={"textarea"}
              fullWidth
              label={"Note/Reason"}
              onChange={onReasonChange}
              placeholder={"Enter Note/Reason"}
              multiline
              required
              rows={4}
              value={reason}
            />
          </CardContent>
          <CardActions className={"actions"}>
            <Button size={"small"} variant={"outlined"} onClick={onClose}>
              Cancel
            </Button>
            <Button
              size={"small"}
              type={"submit"}
              variant={"contained"}
              endIcon={loading && <CircularProgress size="1.5rem" />}
              disabled={loading}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </form>
    </Modal>
  );
}
