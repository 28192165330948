import React, { useState, useEffect, forwardRef } from "react";
import {
  Alert,
  Avatar,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  RichTreeView,
  TreeItem2,
  TreeItem2Label,
  useTreeViewApiRef,
} from "@mui/x-tree-view";
import { styled } from "@mui/system";
import { useAnnexData } from "../../../../services/hooks/useAnnexData";
import { HideSourceOutlined } from "@mui/icons-material";

const Item = styled(Typography)(({ theme }) => ({
  padding: "2px 8px;",
  textAlign: "left",
}));

const ItemLabel = styled(Typography)(({ theme }) => ({
  padding: "2px 8px;",
  textAlign: "left",
  fontWeight: "bold",
}));

const CustomTreeItem = styled(TreeItem2)(({ theme }) => ({
  ".MuiTreeItem-iconContainer": {
    paddingTop: theme.spacing(1),
  },
  ".MuiTreeItem-content": {
    alignItems: "start",
  },
}));

const HeightRestrictedTreeView = styled(RichTreeView)(({ theme }) => ({
  height: "500px",
  width: "100%",
  overflow: "auto",
}));

const renderTreeViewOptionTable = (node) => {
  if (!(node && node.id)) {
    return;
  }
  return (
    <Table
      size="small"
      aria-label="a dense table"
      sx={{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
        },
      }}
    >
      <TableBody>
        <TableRow>
          <TableCell padding="none" width={"10%"}>
            <ItemLabel variant="body2">Code:</ItemLabel>
          </TableCell>
          <TableCell padding="none" width={"90%"}>
            <Item variant="body2">{node.code}</Item>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell padding="none">
            <ItemLabel variant="body2">Term:</ItemLabel>
          </TableCell>
          <TableCell padding="none">
            <Item variant="body2">{node.term}</Item>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell padding="none">
            <ItemLabel variant="body2">Definition:</ItemLabel>
          </TableCell>
          <TableCell padding="none">
            <Item variant="body2">{node.definition}</Item>
          </TableCell>
        </TableRow>
        {!!node.nonImdrf && (
          <TableRow>
            <TableCell padding="none">
              <ItemLabel variant="body2">Non-IMDRF:</ItemLabel>
            </TableCell>
            <TableCell padding="none">
              <Item variant="body2">{node.nonImdrf}</Item>
            </TableCell>
          </TableRow>
        )}
        {!node.isSelectable && (
          <TableRow>
            <TableCell padding="none">
              <ItemLabel variant="body2">Status:</ItemLabel>
            </TableCell>
            <TableCell padding="none">
              <Item variant="body2">Not selectable</Item>
            </TableCell>
          </TableRow>
        )}
        {!node.isSelectable && (
          <TableRow>
            <TableCell padding="none" colSpan={2}>
              <Alert severity="error">
                Please use a more detailed term within the heirarchy.
              </Alert>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

function TreeItemLabelWithLoading(props) {
  const { loading, id: itemId, node, ...other } = props;
  return (
    <TreeItem2Label {...other}>
      {node.term ? (
        renderTreeViewOptionTable(node)
      ) : (
        <ItemLabel variant="body2">{node.label}</ItemLabel>
      )}
      {loading && <CircularProgress size="1em" sx={{ marginLeft: 0.5 }} />}
    </TreeItem2Label>
  );
}

const TreeItemWithLoading = forwardRef(function TreeItemWithLoadingInternal(
  props,
  ref
) {
  const node = props.apiref.current.getItem(props.itemId);
  return (
    <CustomTreeItem
      ref={ref}
      {...props}
      slots={{
        label: TreeItemLabelWithLoading,
      }}
      slotProps={{
        label: {
          id: props.itemId,
          node: node,
        },
      }}
      disabled={!node.isSelectable}
    />
  );
});

export default function IMDRFComponent({
  projectId,
  annexes,
  actionRef,
  selectedCode,
  setSelectedCode,
}) {
  const [items, setItems] = useState([]);

  const [inputText, setInputText] = useState("");
  const [expanded, setExpanded] = useState([]);
  const apiRef = useTreeViewApiRef();

  const { getAnnexData } = useAnnexData();

  function handleSearchChange(event) {
    setInputText(event.target.value);
  }

  useEffect(() => {
    async function fetchData(annex) {
      await getAnnexData(annex, projectId);
      // set only unique items
      setItems((prev) => {
        return prev.includes(annex) ? prev : [...prev, annex];
      });
    }

    if (annexes.length > 0) {
      annexes.forEach((a) => fetchData(a));
    }
    // disabling linter for this dependency array because adding getAnnexData causes this to re-fire infinitely
    // eslint-disable-next-line
  }, [annexes, projectId]);

  return (
    <Stack direction="column" spacing={1.5} className="categorize-modal">
      <TextField
        className="categorize-modal-textfield"
        label="IMDRF Code"
        fullWidth
        size="small"
        value={inputText}
        onChange={handleSearchChange}
      />
      {items.length > 0 ? (
        <HeightRestrictedTreeView
          apiRef={apiRef}
          expandedItems={expanded}
          expansionTrigger="iconContainer"
          onItemExpansionToggle={(event, itemId, isExpanded) => {
            // stop propagation to prevent the selected handler from triggering
            // when a user expands a selectable option
            event.stopPropagation();
            if (isExpanded) {
              setExpanded([...expanded, `${itemId}`]);
            } else {
              setExpanded(expanded.filter((e) => e !== itemId));
            }
          }}
          selectedItems={selectedCode}
          onSelectedItemsChange={(event, itemIds) => {
            const node = apiRef.current.getItem(itemIds);
            if (node.isSelectable) {
              setSelectedCode(node);
            }
          }}
          items={items}
          getItemId={(item) => `${item.id}`}
          getItemLabel={(item) => item.label || item.term}
          slots={{ item: TreeItemWithLoading }}
          slotProps={{
            item: {
              apiref: apiRef,
            },
          }}
        />
      ) : (
        <Stack
          direction="column"
          spacing={1.5}
          alignItems={"center"}
          width="100%"
          style={{ marginTop: "25px" }}
        >
          <Avatar style={{ padding: "5px" }}>
            <HideSourceOutlined color="disabled" fontSize="large" />
          </Avatar>
          <Typography variant="h5" fontWeight="bold">
            No IMDRF Codes
          </Typography>
          <Typography variant="body2">
            There are no IMDRF codes that match this annex and term.
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
