import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatUpsertProjectData } from "../../../models/Projects";
import EditProject from "./EditProject";
import { apiPrefix } from "../../../utils/main";
import { useFetch } from "../../../services/hooks/useFetch";

export default function CreateProject({ defaultValues, onCancel }) {
  const navigate = useNavigate();
  const {
    data: projectData,
    isLoading: projectSaving,
    update: createProject,
  } = useFetch();

  async function handleCreateProject(projectData, keywords) {
    const newProject = formatUpsertProjectData(projectData);
    createProject(`${apiPrefix}/projects`, newProject);
  }

  useEffect(() => {
    if (!projectSaving && !!projectData.id) {
      navigate(`/details?projectId=${projectData.id}`);
    }
  }, [projectSaving, projectData, navigate]);

  return (
    <EditProject
      defaultValues={defaultValues}
      handleSave={handleCreateProject}
      onCancel={onCancel}
      mode={"create"}
    />
  );
}
