import {
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { projectsPrefix } from "../../services/ProjectsServices";
import MakeModalComponent from "../shared/MakeModalComponent";
import StandardDrawerFooter from "../shared/StandardDrawerFooter";
import { useFetch } from "../../services/hooks/useFetch";

export default function ContactUsModal({ closeModal }) {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState("");
  const [phone, setPhone] = useState("");
  const [contactMethod, setContactMethod] = useState("email");
  const {
    update: sendProjectUpgrade,
    status: projectUpgradeStatus,
    isLoading: projectUpgradeSending,
  } = useFetch();

  async function handleSubmit() {
    if (loading) {
      return;
    }
    setLoading(true);
    const payload = {
      phoneNumber: phone,
      preferredContactMethod: contactMethod,
      additionalComments: comments,
    };
    sendProjectUpgrade(`${projectsPrefix}/${projectId}/upgrade`, payload);
  }

  useEffect(() => {
    if (!projectUpgradeSending && projectUpgradeStatus === 204) {
      closeModal();
    }
  }, [projectUpgradeSending, projectUpgradeStatus, closeModal]);

  function handleCommentsChange(event) {
    setComments(event.target.value);
  }

  function handlePhoneChange(event) {
    setPhone(event.target.value);
  }

  function handleChange(event) {
    setContactMethod(event.target.value);
  }

  const isInfoValid = () => {
    if (contactMethod === "email" && comments?.trim() !== "") {
      return false;
    }
    if (
      contactMethod === "phone" &&
      phone?.trim() !== "" &&
      comments?.trim() !== ""
    ) {
      return false;
    }
    return true;
  };

  const ModalContent = (
    <div className="contact-us-modal">
      <Typography variant="body2">
        Thanks for your interest in 3Aware! <br />
        Please provide your information and we will contact you as soon as
        possible.
      </Typography>
      <FormControl>
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          How would you prefer to be contacted?
        </Typography>
        <RadioGroup row defaultValue={"email"} value={contactMethod}>
          <FormControlLabel
            value="email"
            control={<Radio onClick={handleChange} />}
            label="Email"
          />
          <FormControlLabel
            value="phone"
            control={<Radio onClick={handleChange} />}
            label="Phone (please specify)"
          />
        </RadioGroup>
      </FormControl>
      {contactMethod === "phone" && (
        <TextField
          id="phone"
          label="Phone Number"
          value={phone}
          onChange={handlePhoneChange}
          fullWidth
          inputProps={{ "data-testid": "ContactUsModal-phone" }}
        />
      )}
      <TextField
        label="Comments"
        fullWidth
        maxRows={8}
        minRows={8}
        multiline
        value={comments}
        onChange={handleCommentsChange}
        placeholder="Comments"
        inputProps={{ "data-testid": "ContactUsModal-comments" }}
      />
    </div>
  );

  return (
    <Modal open={true} onClose={closeModal}>
      <div className="contact-us-modal-card">
        <MakeModalComponent
          header={<Typography variant="h5"> Contact Us</Typography>}
          content={ModalContent}
          footer={
            <StandardDrawerFooter
              leftBtnLabel={"Cancel"}
              onLeftBtn={closeModal}
              rightBtnLabel={"Send Message"}
              onRightBtn={handleSubmit}
              loading={isInfoValid() || loading}
              buttonsAlignment={"align-between"}
              displayLoadingRightBtn={loading}
            />
          }
        />
      </div>
    </Modal>
  );
}
