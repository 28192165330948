import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Link as MuiLink,
  Skeleton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../services/hooks/useFetch";
import { convertDate, userApiPrefix } from "../../utils/main";
import Marketing from "../shared/Marketing";
import { useFusionAuth } from "@fusionauth/react-sdk";

export default function Agreements() {
  const navigate = useNavigate();
  const environment = window.env.environment;
  const [newAgreements, setNewAgreements] = useState({});
  const {
    data: envAgreements,
    fetch: getAgreementsForEnvironment,
    isLoading: envAgreementsLoading,
    status: envAgreementsStatus,
  } = useFetch();
  const { update: bulkAgree } = useFetch();
  const { refreshToken, fetchUserInfo } = useFusionAuth();

  function openAgreement(url) {
    window.open(url);
  }

  function isAgreementAccepted(agreement) {
    return !!agreement.acceptance || !!newAgreements[agreement.id];
  }

  function changeAgreementChecked(agreement) {
    if (newAgreements[agreement.id]) {
      const { [agreement.id]: _, ...updatedAgreements } = newAgreements;
      setNewAgreements(updatedAgreements);
    } else {
      setNewAgreements({
        ...newAgreements,
        [agreement.id]: {
          agreementId: agreement.id,
          versionId: agreement.currentVersion.id,
        },
      });
    }
  }

  function handleAgree(event) {
    event.preventDefault();
    bulkAgree(
      `${userApiPrefix}/user_agreement/accept/bulk`,
      {
        userAgreements: Object.values(newAgreements),
      },
      undefined,
      undefined,
      async () => {
        await refreshToken();
        await fetchUserInfo();
        navigate("/");
      }
    );
  }

  useEffect(() => {
    if (!envAgreementsLoading && envAgreementsStatus === null) {
      getAgreementsForEnvironment(
        `${userApiPrefix}/user_agreement/${environment}`
      );
    }
  }, [
    environment,
    envAgreementsLoading,
    envAgreementsStatus,
    getAgreementsForEnvironment,
  ]);

  return (
    <div className="signup-layout">
      <Marketing />
      <div className="authentication">
        <form onSubmit={handleAgree} className="login-form">
          <div>
            <Typography variant="h6" gutterBottom>
              You have Logged In!
            </Typography>
            <Typography variant="body2" gutterBottom>
              Before you continue, you must agree to the terms of all the
              documents below.
            </Typography>
          </div>
          {envAgreementsLoading ? (
            <Skeleton animation="wave" />
          ) : (
            <div className="terms-of-service-box">
              {envAgreements &&
                envAgreements.map((agreement) => {
                  return (
                    <div
                      className="terms-of-service"
                      key={`agreement-${agreement.id}`}
                    >
                      <Checkbox
                        id={`checkbox-${agreement.id}`}
                        onChange={() => changeAgreementChecked(agreement)}
                        checked={isAgreementAccepted(agreement)}
                        disabled={!!agreement.acceptance}
                        inputProps={{
                          "data-testid": `Agreement-checkbox-${agreement.id}`,
                        }}
                      />
                      <Typography variant="body2">
                        Checking this box means you agree with the{" "}
                        <MuiLink
                          onClick={() =>
                            openAgreement(agreement.currentVersion.url)
                          }
                          className="terms-of-service-text"
                          variant={"subtitle2"}
                        >
                          {agreement.currentVersion.name}
                        </MuiLink>
                        {"."}
                        {!!agreement.acceptance &&
                          ` (agreed ${convertDate
                            .stringToDate(agreement.acceptance.acceptedDate)
                            .toDateString()})`}
                      </Typography>
                    </div>
                  );
                })}
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            data-testid="Agreements-submit"
            disabled={
              !envAgreements.every((a) => {
                return !!a.acceptance || !!newAgreements[a.id];
              })
            }
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
}
