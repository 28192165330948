import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Stack,
} from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import { green, deepPurple, red, blueGrey } from "@mui/material/colors";

export default function ListItem({
  index,
  style,
  patientSummary,
  patientSelected,
  onPatientSelected,
}) {
  function checkArray(arr) {
    return arr && arr.length !== 0;
  }
  const year = patientSummary.procedureDate?.getUTCFullYear();
  const active = patientSummary.id === patientSelected.id;

  return (
    <Card
      key={index}
      style={style}
      className={"patient-list-item-card"}
      onClick={() => onPatientSelected(patientSummary)}
      sx={{ bgcolor: active ? "action.selected" : "" }}
    >
      <CardActionArea>
        <CardContent>
          {patientSummary.isIncluded && (
            <Box
              className="patient-list-icon-bubble"
              sx={{ bgcolor: green[50] }}
            >
              <ThumbUpOutlinedIcon sx={{ color: green[600] }} />
            </Box>
          )}
          {patientSummary.isIncluded === false && (
            <Box
              className="patient-list-icon-bubble"
              sx={{ background: red[50] }}
            >
              <BlockOutlinedIcon sx={{ color: red[600] }} />
            </Box>
          )}
          {patientSummary.isIncluded === null && (
            <Box
              className="patient-list-icon-bubble"
              sx={{ background: blueGrey[50] }}
            >
              <PersonOutlinedIcon sx={{ color: blueGrey[600] }} />
            </Box>
          )}

          <Stack
            direction={"column"}
            alignItems={"flex-start"}
            className="patient-list-info"
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              className="patient-list-top"
            >
              <Typography variant="body1">{`Patient ${patientSummary.patientNumber}`}</Typography>
              <Stack direction={"row"} className="categorization">
                {checkArray(patientSummary.adverseEvents) && (
                  <WarningAmberOutlinedIcon sx={{ color: red[700] }} />
                )}
                {patientSummary.clinicalBenefit && (
                  <MedicalServicesOutlinedIcon
                    sx={{ color: deepPurple[600] }}
                  />
                )}
                {patientSummary.performance && (
                  <TrendingUpOutlinedIcon sx={{ color: green[700] }} />
                )}
              </Stack>
            </Stack>
            <Typography variant="body2">Index Procedure: {year}</Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
