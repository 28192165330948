import { useSearchParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { Link, Tooltip, Typography } from "@mui/material";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";

import IndicationForUseModal from "./IndicationForUseModal";
import TargetLocationModal from "./TargetLocationModal";
import DeviceDeficiencyModal from "./DeviceDeficiencyModal";
import { projectsPrefix } from "../../../services/ProjectsServices";
import { useFetch } from "../../../services/hooks/useFetch";
import { formatPatient } from "../../../models/patient";

const openEnum = {
  targetLocation: "target location modal",
  indicationForUse: "indication for use modal",
  deviceDeficient: "device deficient modal",
};

function findReason(reasons, search) {
  const reason = reasons.find((reason) => reason.reasonType === search);
  return reason || {};
}

export default function IndexProcedureAdditionalInfo({ data, updateData }) {
  const { targetLocation, indicationForUse, deviceDeficient } = data;
  const reasons = data.reasons || [];
  const targetReason = findReason(reasons, "targetLocation");
  const indicationReason = findReason(reasons, "indicationForUse");
  const deficientReason = findReason(reasons, "deviceDeficient");

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState();
  const [loading, setLoading] = useState(false);

  const {
    update: storePatientAssignment,
    data: assignmentData,
    isLoading: isSaving,
  } = useFetch();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  const onClose = useCallback(() => {
    setOpen(false);
    setEdit(null);
  }, [setEdit, setOpen]);

  function onOpen(modal) {
    setOpen(modal);
  }

  function onEdit(modal) {
    switch (modal) {
      case openEnum.targetLocation:
        setEdit({ targetLocation, reason: targetReason.reason });
        break;
      case openEnum.indicationForUse:
        setEdit({ indicationForUse, reason: indicationReason.reason });
        break;
      case openEnum.deviceDeficient:
        setEdit({ deviceDeficient, reason: deficientReason.reason });
        break;
      default:
        break;
    }
    setOpen(modal);
  }

  function onSave(newData) {
    const patientId = data.id;
    storePatientAssignment(
      `${projectsPrefix}/${projectId}/patients/${patientId}/reasons`,
      newData,
      "PUT"
    );
  }

  useEffect(() => {
    if (!isSaving && !!assignmentData.id) {
      setLoading(false);
      updateData(formatPatient(assignmentData));
      onClose();
    }
  }, [isSaving, assignmentData, setLoading, updateData, onClose]);

  return (
    <>
      <Typography className={"section-title"} variant={"h6"}>
        Additional Information
      </Typography>
      {!targetLocation && (
        <Link
          onClick={() => onOpen(openEnum.targetLocation)}
          component={"button"}
          variant={"subtitle2"}
        >
          Add Target Location
        </Link>
      )}
      {targetLocation && (
        <AditionalInfoContainer
          classes={{ root: "target-location-container" }}
          label={"Target Location"}
          onClickEdit={() => onEdit(openEnum.targetLocation)}
          contentTitle={targetLocation}
          contentReason={targetReason.reason}
        />
      )}
      {!indicationForUse && (
        <Link
          onClick={() => onOpen(openEnum.indicationForUse)}
          component={"button"}
          variant={"subtitle2"}
        >
          Add Indication For Use
        </Link>
      )}
      {indicationForUse && (
        <AditionalInfoContainer
          classes={{ root: "indication-of-use-container" }}
          label={"Indication for Use"}
          onClickEdit={() => onEdit(openEnum.indicationForUse)}
          contentTitle={indicationForUse}
          contentReason={indicationReason.reason}
        />
      )}

      {(deviceDeficient === null || deviceDeficient === undefined) && (
        <Link
          onClick={() => onOpen(openEnum.deviceDeficient)}
          component={"button"}
          variant={"subtitle2"}
        >
          Add Device Deficiency
        </Link>
      )}
      {deviceDeficient !== null && deviceDeficient !== undefined && (
        <AditionalInfoContainer
          classes={{ root: "device-deficient-container" }}
          label={"Device Deficient"}
          onClickEdit={() => onEdit(openEnum.deviceDeficient)}
          contentTitle={
            deviceDeficient
              ? "Yes (device is deficient)"
              : "No (device isn't deficient)"
          }
          contentReason={deficientReason.reason}
        />
      )}
      {open === openEnum.targetLocation && (
        <TargetLocationModal
          editData={edit}
          onClose={onClose}
          onSave={onSave}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {open === openEnum.indicationForUse && (
        <IndicationForUseModal
          editData={edit}
          onClose={onClose}
          onSave={onSave}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {open === openEnum.deviceDeficient && (
        <DeviceDeficiencyModal
          editData={edit}
          onClose={onClose}
          onSave={onSave}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
}

function AditionalInfoContainer({
  classes,
  label,
  onClickEdit,
  contentTitle,
  contentReason,
}) {
  return (
    <div className={classes.root}>
      <div className={"header"}>
        <Typography variant={"body2"}>{label}</Typography>
        <Link
          className={"edit-link"}
          component="button"
          variant="subtitle2"
          onClick={onClickEdit}
        >
          Edit
        </Link>
      </div>
      <div className={"content"}>
        <Typography variant={"subtitle1"}>{contentTitle}</Typography>
        <Tooltip title={contentReason || ""} placement={"right"}>
          <StickyNote2OutlinedIcon
            fontSize={"small"}
            sx={{ color: "action.active" }}
          />
        </Tooltip>
      </div>
    </div>
  );
}
