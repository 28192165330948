import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Tab,
  Tabs,
  CircularProgress,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Visits from "./visits/Visits";
import CodeList from "../../shared/codelist/CodeList";
import VisitsDiagnosesProceduresSkeleton from "./skeletons/VisitsDiagnosesProceduresSkeleton";
import { useFetch } from "../../../services/hooks/useFetch";
import { apiPrefix } from "../../../utils/main";
import { CurrentPatient } from "../ProjectDetails";
import { convertDate } from "../../../utils/main";
import { getImdrfEnabled } from "./categorize/CategorizeUtils";

export default function VisitsDiagnosesProcedures({
  patient,
  patientLoading,
  updatePatient,
  getPatientRequest,
  updatePatientList,
}) {
  const {
    isEncountersUpdated,
    setIsEncountersUpdated,
    isDiagnosisCodesUpdated,
    setIsDiagnosisCodesUpdated,
    isProcedureCodesUpdated,
    setIsProcedureCodesUpdated,
    encounters,
    setEncounters,
    procedureCodes,
    setProcedureCodes,
    diagnosisCodes,
    setDiagnosisCodes,
    projectSettings,
  } = useContext(CurrentPatient);
  const imdrfEnabled = getImdrfEnabled(projectSettings);
  const [tabSelected, setTabSelected] = useState(0);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  const { isLoading: encountersLoading, fetch: fetchEncounters } = useFetch();

  const { isLoading: diagnosisCodesLoading, fetch: fetchDiagnosisCodes } =
    useFetch();

  const { isLoading: procedureCodesLoading, fetch: fetchProcedureCodes } =
    useFetch();

  function handleTabChange(_, value) {
    setTabSelected(value);
  }

  useEffect(() => {
    if (!patient.id || !projectId) {
      return;
    }
    if (!isEncountersUpdated) {
      fetchEncounters(
        `${apiPrefix}/projects/${projectId}/patients/${patient.id}/encounters/`,
        (data) => {
          setEncounters(formatEncounterDates(data));
          setIsEncountersUpdated(true);
        }
      );
    }
  }, [
    patient.id,
    projectId,
    tabSelected,
    fetchDiagnosisCodes,
    fetchProcedureCodes,
    fetchEncounters,
    setEncounters,
    isEncountersUpdated,
    setIsEncountersUpdated,
  ]);

  function formatEncounterDates(data) {
    const { stringToDate } = convertDate;
    return data.map((encounter) => {
      return {
        ...encounter,
        arriveDate: stringToDate(encounter.arriveDate),
      };
    });
  }

  useEffect(() => {
    if (!patient.id || !projectId) {
      return;
    }
    if (tabSelected === 1 && !isDiagnosisCodesUpdated) {
      fetchDiagnosisCodes(
        `${apiPrefix}/projects/${projectId}/patients/${patient.id}/diagnosisCodes`,
        (data) => {
          setDiagnosisCodes(data);
          setIsDiagnosisCodesUpdated(true);
        }
      );
    }
  }, [
    patient.id,
    projectId,
    tabSelected,
    fetchDiagnosisCodes,
    isDiagnosisCodesUpdated,
    setDiagnosisCodes,
    setIsDiagnosisCodesUpdated,
  ]);

  useEffect(() => {
    if (!patient.id || !projectId) {
      return;
    }
    if (tabSelected === 2 && !isProcedureCodesUpdated) {
      fetchProcedureCodes(
        `${apiPrefix}/projects/${projectId}/patients/${patient.id}/procedureCodes`,
        (data) => {
          setProcedureCodes(data);
          setIsProcedureCodesUpdated(true);
        }
      );
    }
  }, [
    patient.id,
    projectId,
    tabSelected,
    fetchProcedureCodes,
    setProcedureCodes,
    isProcedureCodesUpdated,
    setIsProcedureCodesUpdated,
  ]);

  if (!patient.id || patientLoading || !encounters || !encounters.length) {
    return <VisitsDiagnosesProceduresSkeleton />;
  }

  return (
    <div className="visitsDiagnosesProcedures">
      <Card>
        <CardHeader
          title="Visits, Diagnoses and Procedures"
          titleTypographyProps={{
            align: "left",
            className: "card-title",
            variant: "body1",
          }}
        />
        <CardContent>
          <Tabs
            className={"tabs"}
            value={tabSelected}
            onChange={handleTabChange}
            centered
          >
            <Tab
              className="tab"
              label={
                <TabTitle title={"All Visits"} count={encounters.length} />
              }
            />
            <Tab
              className="tab"
              label={
                <TabTitle
                  title={"All Diagnoses"}
                  count={patient.diagnosisCodeCount}
                />
              }
            />
            <Tab
              className="tab"
              label={
                <TabTitle
                  title={"All Procedures"}
                  count={patient.procedureCodeCount}
                />
              }
            />
          </Tabs>
        </CardContent>
        <CardContent align="left">
          {tabSelected === 0 && (
            <Visits
              loading={encountersLoading}
              patient={patient}
              updatePatient={updatePatient}
              getPatientRequest={getPatientRequest}
              encounters={encounters}
              updatePatientList={updatePatientList}
              imdrfEnabled={imdrfEnabled}
            />
          )}
          {tabSelected === 1 && (
            <CodeList
              loading={diagnosisCodesLoading}
              codes={diagnosisCodes}
              patientId={patient.id}
              visits={encounters}
              updatePatient={updatePatient}
              updatePatientList={updatePatientList}
              imdrfEnabled={imdrfEnabled}
            />
          )}
          {tabSelected === 2 && (
            <CodeList
              loading={procedureCodesLoading}
              codes={procedureCodes}
              patientId={patient.id}
              visits={encounters}
              updatePatient={updatePatient}
              updatePatientList={updatePatientList}
              imdrfEnabled={imdrfEnabled}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
}

function TabTitle({ title, count }) {
  return (
    <span className="tab-title">
      {title}{" "}
      {count !== undefined ? (
        <Chip label={count} size="small" />
      ) : (
        <CircularProgress size={20} />
      )}
    </span>
  );
}
