import React, { useContext, useMemo, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import CategorizeModal from "./categorize/CategorizeModal";
import MarkModal from "./MarkModal";
import SelectedPatientSkeleton from "./skeletons/SelectedPatientSkeleton";
import PatientActivity from "../../shared/activity/PatientActivity";
import VisitsDrawer from "./VisitsDrawer";
import { updatePatientVisits } from "../../../models/patient";
import { CurrentPatient } from "../ProjectDetails";
import CategorizeModalV2 from "./categorize/CategorizeModalV2";
import { getImdrfEnabled } from "./categorize/CategorizeUtils";

const openEnum = {
  categorizeModal: "categorize-modal",
  markModal: "mark-modal",
  patientActivity: "patient-activity",
  patientVisitDrawer: "display-visit-drawer",
};

export default function SelectedPatientMetadata({
  patientMaxMode,
  setPatientMaxMode,
  goPrevPatient,
  goNextPatient,
  updatePatientList,
  updatePatient,
}) {
  const { patient, patientLoading, projectSettings } =
    useContext(CurrentPatient);
  const imdrfEnabled = getImdrfEnabled(projectSettings);
  const [open, setOpen] = useState(false);
  const [markButton, setMarkButton] = useState();
  const [visitToLoadId, setVisitToLoadId] = useState(null);

  function handleCategorizeModal(event) {
    setOpen(openEnum.categorizeModal);
    setMarkButton(event.currentTarget);
  }

  function handleMarkModal(event) {
    setOpen(openEnum.markModal);
    setMarkButton(event.currentTarget);
  }

  function handlePatientActivity() {
    setOpen(openEnum.patientActivity);
  }

  function handleOnClose() {
    setOpen(false);
    setMarkButton(null);
  }

  function onLoadVisit(visitId) {
    setVisitToLoadId(visitId);
    setOpen(openEnum.patientVisitDrawer);
  }

  function handleUpdatePatient(data) {
    const visitsUpdated = updatePatientVisits(patient.encounters, data);
    updatePatient({ encounters: visitsUpdated });
  }

  const isIncludedReason = useMemo(() => {
    if (
      patient.isIncluded === null ||
      !patient.reasons ||
      patient.reasons.length === 0
    ) {
      return "";
    }
    const reasonObj = patient.reasons.find(
      (item) => item.reasonType === "isIncluded"
    );
    return reasonObj.reason;
  }, [patient.reasons, patient.isIncluded]);

  if (!patient || !patient.id) {
    return <SelectedPatientSkeleton />;
  }

  return (
    <Paper className="patient-header" elevation={0} square>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          className={"patient-header-left"}
        >
          <IconButton onClick={() => setPatientMaxMode(!patientMaxMode)}>
            {!patientMaxMode ? <OpenInFullIcon /> : <CloseFullscreenIcon />}
          </IconButton>
          <Divider orientation="vertical" flexItem />
          <Stack direction={"row"} alignItems={"center"}>
            <IconButton
              onClick={goPrevPatient}
              sx={{ "&:hover": { bgcolor: "action.hover" } }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant={"h6"}>
              {`Patient ${patient.patientNumber}`}
            </Typography>
            {patientLoading && <CircularProgress size={"1rem"} />}
            {/*   TO-DO: Research why MUI is overwriting the default background */}
            <IconButton
              onClick={goNextPatient}
              sx={{ "&:hover": { bgcolor: "action.hover" } }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Stack>
          <Divider orientation="vertical" flexItem />

          {patient.isIncluded && (
            <Chip
              label={"Included"}
              size="small"
              sx={{ bgcolor: green[600], color: "common.white" }}
              onDelete={() => {}}
              deleteIcon={
                <Tooltip className="reason-icon" title={isIncludedReason}>
                  <StickyNote2OutlinedIcon
                    fontSize="small"
                    style={{ color: "#F0F0F0" }}
                  />
                </Tooltip>
              }
            ></Chip>
          )}
          {patient.isIncluded === false && (
            <Chip
              label={"Excluded"}
              size="small"
              sx={{ bgcolor: red[600], color: "common.white" }}
              onDelete={() => {}}
              deleteIcon={
                <Tooltip className="reason-icon" title={isIncludedReason}>
                  <StickyNote2OutlinedIcon
                    fontSize="small"
                    style={{ color: "#F0F0F0" }}
                  />
                </Tooltip>
              }
            ></Chip>
          )}
        </Stack>
        <Stack direction={"row"} className={"patient-header-right"}>
          <Button
            size={"small"}
            variant={"outlined"}
            className={"categorize-button"}
            onClick={handlePatientActivity}
          >
            View Patient Activity
          </Button>
          <Button
            size={"small"}
            variant={"outlined"}
            className={"categorize-button"}
            onClick={handleCategorizeModal}
          >
            Categorize Patient
          </Button>
          <Button
            size={"small"}
            variant="contained"
            className={"categorize-button"}
            onClick={handleMarkModal}
          >
            Mark Patient As
          </Button>
        </Stack>
        {open === openEnum.categorizeModal &&
          (imdrfEnabled ? (
            <CategorizeModalV2
              onClose={handleOnClose}
              open={true}
              anchorEl={markButton}
              patientId={patient.id}
              patientNumber={patient.patientNumber}
              updateList={updatePatientList}
            />
          ) : (
            <CategorizeModal
              onClose={handleOnClose}
              open={true}
              anchorEl={markButton}
              patientId={patient.id}
              updateList={updatePatientList}
            />
          ))}
        {open === openEnum.markModal && (
          <MarkModal
            onClose={handleOnClose}
            open={true}
            anchorEl={markButton}
            patientId={patient.id}
            updateList={updatePatientList}
          />
        )}
        {open === openEnum.patientActivity && (
          <PatientActivity
            onClose={handleOnClose}
            onLoadVisit={onLoadVisit}
            patientId={patient.id}
          />
        )}
        {open === openEnum.patientVisitDrawer && (
          <VisitsDrawer
            toggleDrawer={handleOnClose}
            patientId={patient.id}
            visitId={visitToLoadId}
            updatedList={handleUpdatePatient}
            imdrfEnabled={imdrfEnabled}
          />
        )}
      </Stack>
    </Paper>
  );
}
