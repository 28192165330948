import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Card,
  Chip,
  Drawer,
  Typography,
  CardContent,
  Divider,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import InfoIcon from "@mui/icons-material/Info";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import { emptyEncounter, formatEncounter } from "../../../models/patient";
import theme from "../../../styles/theme";
import EmptyCard from "./EmptyCard";
import SkeletonCard from "./SkeletonCard";
import RelatedVisitModal from "./RelatedVisitModal";
import CodesChips from "./CodesChips";
import NoteText from "./NoteText";
import { compareTwoValues } from "../../../utils/main";
import { useFetch } from "../../../services/hooks/useFetch";
import { projectsPrefix } from "../../../services/ProjectsServices";
import CategorizeModal from "./categorize/CategorizeModal";
import CategorizeModalV2 from "./categorize/CategorizeModalV2";

function showDiffDaysMessage(note) {
  return note.diffDays !== null && !Number.isNaN(note.diffDays);
}

export default function VisitsDrawer({
  toggleDrawer,
  patientId,
  visitId,
  updatedList,
  updatePatientList,
  imdrfEnabled,
}) {
  const [data, setData] = useState(emptyEncounter);
  const [priority, setPriority] = useState([]);
  const [commandPriority, setCommandPriority] = useState(2);
  const [commandTitle, setCommandTitle] = useState(0);
  const [relatedVisitModal, setRelatedVisitModal] = useState(false);
  const [categorizeModal, setCategorizeModal] = useState(false);
  const [mark, setMark] = useState("");
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  const { fetch, isLoading, data: encounterData } = useFetch();

  useEffect(() => {
    fetch(
      `${projectsPrefix}/${projectId}/patients/${patientId}/encounters/${visitId}`,
      (data) => {
        return formatEncounter(data);
      }
    );
  }, [projectId, patientId, visitId, fetch]);

  useEffect(() => {
    if (!isLoading && !!encounterData.id) {
      setData(encounterData);
      setPriority(encounterData.notes);
    }
  }, [isLoading, encounterData]);

  function sortByPriority() {
    const encountersCopy = [...priority].sort((a, b) => {
      return compareTwoValues(a.priority, b.priority);
    });
    if (commandPriority === 1) {
      setPriority(encountersCopy.reverse());
      setCommandPriority(2);
      setCommandTitle(0);
    }
    if (commandPriority === 0 || commandPriority === 2) {
      setPriority(encountersCopy);
      setCommandPriority(1);
      setCommandTitle(0);
    }
  }

  function sortByTitle() {
    const encountersCopy = [...priority].sort((a, b) => {
      const titleA = a.title.toUpperCase();
      const titleB = b.title.toUpperCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });
    if (commandTitle === 0 || commandTitle === 2) {
      setPriority(encountersCopy);
      setCommandTitle(1);
      setCommandPriority(0);
    }
    if (commandTitle === 1) {
      setPriority(encountersCopy.reverse());
      setCommandTitle(2);
      setCommandPriority(0);
    }
  }

  function handleVisitUpdate(updatedVisit) {
    setData((prev) => ({
      ...prev,
      id: updatedVisit.id,
      isRelated: updatedVisit.isRelated,
      reason: updatedVisit.reason,
    }));
  }

  function closeVisitModal() {
    setRelatedVisitModal(false);
    setMark(null);
  }

  function closeCategorizeModal() {
    setCategorizeModal(false);
    setMark(null);
  }

  function handleVisitModal(e) {
    setMark(e.currentTarget);
    setRelatedVisitModal(true);
  }

  function handleCategorizeModal(e) {
    setMark(e.currentTarget);
    setCategorizeModal(true);
  }

  return (
    <Drawer
      anchor="right"
      open={true}
      onClose={toggleDrawer}
      PaperProps={{
        sx: { width: "min(100%, 1052px)" },
      }}
    >
      <div className="drawer-internal-wrapper">
        <div className="visit-modal-header">
          <div className="card-header">
            <div className="modal-header-left">
              <IconButton
                onClick={toggleDrawer}
                sx={{ "&:hover": { bgcolor: "action.hover" } }}
              >
                <ChevronRightIcon />
              </IconButton>
              {/*
                This change was to allow 0 days post index procedures
                to support patients with multiple device events
                TODO: Figure out a better solution to the multiple device event issue
              */}
              {data && !data.indexProcedure && data.diffDays !== undefined && (
                <Typography variant="subtitle1">
                  {data.diffDays} Days post index procedure
                </Typography>
              )}
              {data && data.indexProcedure && data.arriveDate && (
                <Typography variant="subtitle1">
                  Index Procedure: {data.arriveDate.getUTCFullYear()}
                </Typography>
              )}
              {data.isRelated === false && (
                <Chip
                  label="Unrelated"
                  size="small"
                  sx={{ borderRadius: 3 }}
                  onDelete={() => {}}
                  deleteIcon={
                    <Tooltip title={data.reason}>
                      <StickyNote2OutlinedIcon
                        fontSize="small"
                        style={{ color: theme.palette.action.active }}
                      />
                    </Tooltip>
                  }
                />
              )}
            </div>
            <div className="modal-header-right">
              <Button
                size={"small"}
                variant={"outlined"}
                className={"categorize-button"}
                onClick={handleCategorizeModal}
              >
                Categorize Patient
              </Button>
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                id="Mark Visit Button"
                onClick={handleVisitModal}
              >
                {data.isRelated || data.isRelated === null
                  ? "Mark Visit As Unrelated"
                  : "Mark Visit As Related"}
              </Button>
            </div>
            {categorizeModal &&
              (imdrfEnabled ? (
                <CategorizeModalV2
                  onClose={closeCategorizeModal}
                  open={true}
                  anchorEl={mark}
                  patientId={patientId}
                  updateList={updatePatientList}
                />
              ) : (
                <CategorizeModal
                  onClose={closeCategorizeModal}
                  open={true}
                  anchorEl={mark}
                  patientId={patientId}
                  updateList={updatePatientList}
                />
              ))}
          </div>
        </div>
        <Divider flexItem />
        <div className="modal-content-visits">
          <div className="modal-content-visits-left">
            {data.encounterNumber && (
              <div className={"encounter-type"}>
                <Typography className={"label"}>Visit ID:</Typography>
                <Typography className={"type"} variant={"body2"}>
                  {data.encounterNumber}
                </Typography>
              </div>
            )}
            {data.encounterType && (
              <div className={"encounter-type"}>
                <Typography className={"label"}>Visit Type:</Typography>
                <Typography className={"type"} variant={"body2"}>
                  {data.encounterType}
                </Typography>
              </div>
            )}
            <Card className="clinical-notes-insight">
              <CardContent className="clinical-notes-insight-content">
                <div className="clinical-notes-insight-content-title">
                  <Typography variant="body2">
                    Clinical Notes Insights
                  </Typography>
                  <Tooltip
                    title="3Aware extracts information from the Clinical Notes
                        associated to the Visit to highlight references made 
                        to Medications, Allergies, and Other Devices Used 
                        within the unstructured data."
                    placement="top-end"
                  >
                    <InfoIcon
                      size="small"
                      sx={{ color: theme.palette.info.main }}
                    />
                  </Tooltip>
                </div>
                <div className="clinical-notes-insight-items">
                  <div className={"clinical-notes-insight-item"}>
                    <Typography className={"visit-card-label"} variant="body2">
                      Medications
                    </Typography>
                    <Typography variant="body2">
                      {(data.medications || []).join(", ")}
                    </Typography>
                  </div>
                  <div className={"clinical-notes-insight-item"}>
                    <Typography className={"visit-card-label"} variant="body2">
                      Allergies
                    </Typography>
                    <Typography variant="body2">
                      {(data.allergies || []).join(", ")}
                    </Typography>
                  </div>
                  <div className={"clinical-notes-insight-item"}>
                    <Typography className={"visit-card-label"} variant="body2">
                      Other Devices Used
                    </Typography>
                    <Typography variant="body2">
                      {(data.otherDevices || []).join(", ")}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="visit-drawer-card">
              <CardContent className="diagnosis-codes">
                <Typography variant="subtitle1">Diagnoses</Typography>
                {(!data.diagnosisCodes || data.arriveDate === "") && (
                  <SkeletonCard name="empty-data" />
                )}
                {data.diagnosisCodes && data.diagnosisCodes.length > 0 && (
                  <CodesChips codes={data.diagnosisCodes} chipSize={"small"} />
                )}
                {data.diagnosisCodes &&
                  data.diagnosisCodes.length === 0 &&
                  data.arriveDate !== "" && <EmptyCard type="Diagnoses" />}
              </CardContent>
            </Card>
            <Card className="visit-drawer-card">
              <CardContent className="procedure-codes">
                <Typography variant="subtitle1"> Procedures </Typography>
                {(!data.procedureCodes || data.arriveDate === "") && (
                  <SkeletonCard name="empty-data" />
                )}
                {data.procedureCodes && data.procedureCodes.length > 0 && (
                  <CodesChips codes={data.procedureCodes} chipSize={"small"} />
                )}
                {data.procedureCodes &&
                  data.procedureCodes.length === 0 &&
                  data.arriveDate !== "" && <EmptyCard type="Procedures" />}
              </CardContent>
            </Card>
          </div>
          <div className="modal-content-visits-right">
            <div className="modal-content-right-header">
              <div className="modal-content-clinical-notes">
                <StickyNote2OutlinedIcon />
                <Typography variant="body1">Clinical Notes</Typography>
              </div>
            </div>
            <div className="visits-header">
              <div className="visits-row">
                <Typography>Title</Typography>
                {commandTitle !== 1 && (
                  <ArrowDownwardIcon
                    size="small"
                    onClick={sortByTitle}
                    sx={{
                      color:
                        commandTitle === 0
                          ? theme.palette.action.active
                          : theme.palette.action.disable,
                    }}
                  />
                )}
                {commandTitle === 1 && (
                  <ArrowUpwardIcon
                    size="small"
                    onClick={sortByTitle}
                    sx={{ color: theme.palette.action.disable }}
                  />
                )}
              </div>
              <div className="visits-row">
                <Typography>Priority</Typography>
                {commandPriority !== 1 && (
                  <ArrowDownwardIcon
                    size="small"
                    onClick={sortByPriority}
                    sx={{
                      color:
                        commandPriority === 0
                          ? theme.palette.action.active
                          : theme.palette.action.disable,
                    }}
                  />
                )}
                {commandPriority === 1 && (
                  <ArrowUpwardIcon
                    size="small"
                    onClick={sortByPriority}
                    sx={{ color: theme.palette.action.disable }}
                  />
                )}
                <Tooltip
                  title="Clinical Notes with a priority icon may include evidence of an adverse event, clinical or technical failure as identified by the 3Aware system."
                  placement="top-end"
                >
                  <InfoIcon
                    size="small"
                    sx={{ color: theme.palette.info.main }}
                  />
                </Tooltip>
              </div>
            </div>
            <Divider flexItem variant="fullWidth" />
            {(!data.notes || data.arriveDate === "") && (
              <SkeletonCard name="visit-drawer-card" />
            )}
            {data.notes && data.notes.length === 0 && data.arriveDate !== "" && (
              <Card className="visit-drawer-card">
                <CardContent className={"clinical-notes"}>
                  <EmptyCard type="Clinical Notes" />
                </CardContent>
              </Card>
            )}
            {data.notes &&
              data.notes.length > 0 &&
              priority.map((note, index) => (
                <Card
                  key={note.title + "_" + index}
                  className="clinical-notes-note"
                >
                  <div>
                    <div className="modal-content-title-box">
                      <div className="column-1">
                        <Typography
                          variant="h6"
                          className="modal-content-boldtext"
                        >
                          {note.title}
                        </Typography>
                        {note.priority > 0 && (
                          <OfflineBoltOutlinedIcon
                            size="small"
                            sx={{ color: theme.palette.info.main }}
                          />
                        )}
                      </div>
                      <div className="column-2">
                        {showDiffDaysMessage(note) && (
                          <Typography variant="body1">
                            {note.diffDays} days post index procedure
                          </Typography>
                        )}
                      </div>
                    </div>
                    <Divider />
                    <div className="modal-content-subtitle-box">
                      <NoteText note={note} />
                    </div>
                    <Divider />
                    <div className="modal-content-button">
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        className={"categorize-button"}
                        onClick={handleCategorizeModal}
                      >
                        Categorize Patient
                      </Button>
                    </div>
                  </div>
                </Card>
              ))}
          </div>
        </div>
        {relatedVisitModal && data && (
          <RelatedVisitModal
            anchorEl={mark}
            onClose={closeVisitModal}
            visit={data}
            updateVisit={handleVisitUpdate}
            updatedList={updatedList}
            patientId={patientId}
          />
        )}
      </div>
    </Drawer>
  );
}
