import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

export default function DeviceDeficiencyModal({
  editData,
  onClose,
  onSave,
  loading,
  setLoading,
}) {
  const [deficiency, setDeficiency] = useState(
    editData?.deviceDeficient || null
  );
  const [reason, setReason] = useState(editData?.reason || "");

  function onDeficiencyChange(value) {
    setDeficiency(value);
  }
  function onReasonChange(event) {
    setReason(event.target.value);
  }
  function onSubmit(event) {
    event.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    if (deficiency === null || !reason) {
      setLoading(false);
      return;
    }
    const newData = {
      deviceDeficient: deficiency,
      reason,
    };
    onSave(newData);
  }

  return (
    <Modal className={"indication-of-use-modal"} open={true} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <Card classes={{ root: "card" }}>
          <CardHeader
            title={<Typography variant={"h5"}>Device Deficiency</Typography>}
          />
          <CardContent>
            <ButtonGroup disableElevation color={"primary"} size={"small"}>
              <Button
                onClick={() => onDeficiencyChange(true)}
                variant={deficiency === true ? "contained" : "outlined"}
              >
                Yes (device is deficient)
              </Button>
              <Button
                onClick={() => onDeficiencyChange(false)}
                variant={deficiency === false ? "contained" : "outlined"}
              >
                No (device is not deficient)
              </Button>
            </ButtonGroup>
            <TextField
              className={"textarea"}
              fullWidth
              label={"Note/Reason"}
              onChange={onReasonChange}
              placeholder={"Enter Note/Reason"}
              multiline
              required
              rows={4}
              value={reason}
            />
          </CardContent>
          <CardActions className={"actions"}>
            <Button size={"small"} variant={"outlined"} onClick={onClose}>
              Cancel
            </Button>
            <Button
              size={"small"}
              type={"submit"}
              variant={"contained"}
              endIcon={loading && <CircularProgress size="1.5rem" />}
              disabled={loading}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </form>
    </Modal>
  );
}
