import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  Popover,
  CircularProgress,
  Stack,
  Collapse,
  Divider,
} from "@mui/material";
import { projectsPrefix } from "../../../../services/ProjectsServices";
import { useSearchParams } from "react-router-dom";
import { useFetch } from "../../../../services/hooks/useFetch";
import { formatPatient } from "../../../../models/patient";
import { KeyboardArrowRight, ReportProblemOutlined } from "@mui/icons-material";
import IMDRFComponent from "./IMDRFComponent";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { styled } from "@mui/system";

const categoryOptionsWithIcons = [
  {
    category: "Clinical Benefit",
    icon: <MedicalServicesOutlinedIcon />,
  },
  {
    category: "Performance",
    icon: <TrendingUpOutlinedIcon />,
  },
  {
    category: "Adverse Event",
    icon: <ReportProblemOutlined />,
  },
];

const categoryAnnexes = {
  "Clinical Benefit": [],
  "Adverse Event": [
    {
      id: "E",
      label:
        "Annex E: Health Effects - Clinical Signs and Symptoms or Conditions",
      children: [],
    },
    { id: "F", label: "Annex F: Health Effects - Health Impact", children: [] },
  ],
  Performance: [],
};

const IconMenuItem = styled(MenuItem)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    marginRight: theme.spacing(1),
  },
}));

export default function CategorizeModalV2({
  anchorEl,
  open,
  onClose,
  patientId,
  patientNumber,
  selected,
  updateList,
}) {
  const [category, setCategory] = useState(selected?.category || "");
  const [daysPostProcedure, setDaysPostProcedure] = useState(
    selected?.daysPostProcedure || ""
  );
  const [showImdrfSelect, setShowImdrfSelect] = useState(false);
  const [imdrfCode, setImdrfCode] = useState(selected?.imdrfCode || "");
  const [adverseEffect, setAdverseEffect] = useState(
    selected?.adverseEffect || ""
  );
  const [deviceDeficient, setDeviceDeficient] = useState(
    selected?.deviceDeficient || ""
  );
  const [aeTreatment, setAeTreatment] = useState(selected?.aeTreatment || "");
  const [resolution, setResolution] = useState(selected?.resolution || "");
  const [resolutionDaysPostOnset, setResolutionDaysPostOnset] = useState(
    selected?.resolutionDaysPostOnset || ""
  );
  const [reason, setReason] = useState(selected?.reason || "");

  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const [deleting, setDeleting] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const actionRef = React.useRef();

  const {
    update: updateCategorization,
    delete: deleteCategorization,
    isLoading: patientCategorizationLoading,
    data: patientCategorization,
  } = useFetch();

  function formIsValid() {
    return (
      !loading &&
      category &&
      daysPostProcedure &&
      imdrfCode &&
      adverseEffect &&
      deviceDeficient &&
      reason &&
      reason.trim() !== ""
    );
  }

  function setSelectedCode(code) {
    setImdrfCode(code);
    setShowImdrfSelect(false);
  }

  function handleCategoryChange(event) {
    setCategory(event.target.value);
  }

  function handleReasonChange(event) {
    setReason(event.target.value);
  }

  function handleDeleteReasonChange(event) {
    setDeleteReason(event.target.value);
  }

  const handleClose = useCallback(() => {
    setCategory("");
    setReason("");
    onClose();
  }, [setCategory, setReason, onClose]);

  function handleBody() {
    if (category === "Clinical Benefit") {
      return {
        reason: reason,
      };
    }
    if (category === "Performance") {
      return {
        reason: reason,
      };
    }
    return {
      reason: reason,
    };
  }

  function onSubmit(event) {
    event.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);

    // todo: handle updated API request to new endpoint
    const newData = handleBody();
    if (newData.reason.trim() === "") {
      setLoading(false);
      return;
    }
    if (!selected) {
      updateCategorization(
        `${projectsPrefix}/${projectId}/patients/${patientId}/categorize`,
        newData,
        "PUT"
      );
      return;
    }
    let id = selected.id;
    if (selected.category.toLowerCase() === "adverse event") {
      id = selected.adverseEventId;
    }
    updateCategorization(
      `${projectsPrefix}/${projectId}/patients/${patientId}/categorize/${id}`,
      newData,
      "PUT"
    );
  }

  useEffect(() => {
    if (!patientCategorizationLoading && !!patientCategorization.id) {
      setLoading(false);
      updateList(formatPatient(patientCategorization));
      handleClose();
    }
  }, [
    patientCategorizationLoading,
    patientCategorization,
    updateList,
    handleClose,
  ]);

  function deleteAndClose(projectId, patientId, id, isAdverse, payload) {
    deleteCategorization(
      `${projectsPrefix}/${projectId}/patients/${patientId}/categorize/${id}?isAdverseEvent=${isAdverse}`,
      payload
    );
  }

  function onDelete(event) {
    event.preventDefault();
    setLoading(true);

    const payload = {
      reason: deleteReason,
    };
    if (
      selected.category.toLowerCase() === "performance" ||
      selected.category.toLowerCase() === "clinical benefit"
    ) {
      //need to put payload back once the textfield is back
      deleteAndClose(projectId, patientId, selected.id, false, payload);
      return;
    }
    if (selected.category.toLowerCase() === "adverse event") {
      deleteAndClose(
        projectId,
        patientId,
        selected.adverseEventId,
        true,
        payload
      );
    }
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ maxHeight: "80%" }}
      action={actionRef}
    >
      <Typography variant="h6" sx={{ marginTop: "10px", marginLeft: "16px" }}>
        {`Categorize Patient ${patientNumber} ${category && `\\ ${category}`}`}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack direction="column" spacing={1.5} className="categorize-modal">
          <FormControl size="small" fullWidth>
            <InputLabel>Category Type</InputLabel>
            <Select
              label="Category Type"
              value={category}
              onChange={handleCategoryChange}
              disabled={!!selected || deleting}
              variant="outlined"
              SelectDisplayProps={{
                style: { display: "flex", alignItems: "center", gap: "8px" },
              }}
            >
              {categoryOptionsWithIcons.map((c) => {
                return (
                  <IconMenuItem value={c.category} key={c.category}>
                    {c.icon}
                    {c.category}
                  </IconMenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            className="categorize-modal-textfield"
            label="Days Post Procedure"
            placeholder={"0-n"}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            size="small"
            fullWidth
            required
            value={daysPostProcedure}
            onChange={(event) => setDaysPostProcedure(event.target.value)}
            disabled={deleting}
          />
          <Stack direction="row" width={"100%"} alignItems={"center"}>
            <TextField
              className="categorize-modal-textfield"
              label="IMDRF Code"
              value={
                imdrfCode
                  ? `${imdrfCode.code} - ${imdrfCode.term}`
                  : "Please select an IMDRF Code"
              }
              fullWidth
              disabled
              required
              variant={"standard"}
              InputProps={{ disableUnderline: true }}
              sx={{
                "& .MuiInputBase-input": {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
                paddingRight: "2px",
              }}
            />
            <Button
              onClick={() => setShowImdrfSelect(true)}
              variant="contained"
              size="small"
              fullWidth
              endIcon={<KeyboardArrowRight size="1.5rem" />}
              disabled={!category || showImdrfSelect}
            >
              {imdrfCode
                ? "SEARCH FOR A DIFFERENT CODE"
                : "SEARCH FOR IMDRF CODE"}
            </Button>
          </Stack>
          <FormControl size="small" fullWidth>
            <InputLabel required>Adverse Device Effect</InputLabel>
            <Select
              label="Adverse Device Effect"
              value={adverseEffect}
              onChange={(event) => setAdverseEffect(event.target.value)}
              disabled={!!selected || deleting}
              required
              variant="outlined"
            >
              <MenuItem value={"Yes"} key={"yes"}>
                Yes
              </MenuItem>
              <MenuItem value={"No"} key={"no"}>
                No
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" fullWidth>
            <InputLabel required>Device Deficient?</InputLabel>
            <Select
              label="Device Deficient?"
              value={deviceDeficient}
              onChange={(event) => setDeviceDeficient(event.target.value)}
              disabled={!!selected || deleting}
              required
              variant="outlined"
            >
              <MenuItem value={"Yes"} key={"yes"}>
                Yes
              </MenuItem>
              <MenuItem value={"No"} key={"no"}>
                No
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            className="categorize-modal-textfield"
            label="AE Treatment (optional)"
            placeholder={"Treatment Name"}
            size="small"
            fullWidth
            value={aeTreatment}
            onChange={(event) => setAeTreatment(event.target.value)}
            disabled={deleting}
          />
          <FormControl size="small" fullWidth>
            <InputLabel>Resolution (optional)</InputLabel>
            <Select
              label="Resolution (optional)"
              value={resolution}
              onChange={(event) => setResolution(event.target.value)}
              disabled={!!selected || deleting}
              variant="outlined"
            >
              <MenuItem value={"Yes"} key={"yes"}>
                Yes
              </MenuItem>
              <MenuItem value={"No"} key={"no"}>
                No
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            className="categorize-modal-textfield"
            label="Resolution Days Post Onset (optional)"
            placeholder={"0-n"}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            size="small"
            fullWidth
            value={resolutionDaysPostOnset}
            onChange={(event) => setResolutionDaysPostOnset(event.target.value)}
            disabled={deleting}
          />
          <TextField
            className="categorize-modal-textfield"
            label="Notes/Reason"
            fullWidth
            required
            multiline
            rows={4}
            value={reason}
            onChange={handleReasonChange}
            disabled={deleting}
          />
          {deleting && (
            <TextField
              className="categorize-modal-delete-textfield"
              label="Enter Note/Reason for Deleting"
              fullWidth
              maxRows={4}
              multiline
              value={deleteReason}
              onChange={handleDeleteReasonChange}
              placeholder="Please enter a reason for deleting this categorization."
            />
          )}
          {!deleting && (
            <Stack
              direction="row"
              spacing={1}
              justifyContent={"space-between"}
              width={"100%"}
            >
              {category !== "" && !!selected && (
                <Button
                  onClick={() => setDeleting(true)}
                  color="error"
                  variant="contained"
                  size="small"
                  endIcon={loading && <CircularProgress size="1.5rem" />}
                  disabled={loading}
                >
                  Delete
                </Button>
              )}
              <Stack
                direction="row"
                spacing={1}
                justifyContent={"flex-end"}
                width={"100%"}
              >
                <Button onClick={handleClose} variant="outlined" size="small">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={onSubmit}
                  size="small"
                  endIcon={loading && <CircularProgress size="1.5rem" />}
                  disabled={!formIsValid()}
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          )}
          {deleting && (
            <Stack
              direction="row"
              spacing={1}
              justifyContent={"flex-end"}
              width={"100%"}
            >
              <Button
                onClick={() => setDeleting(false)}
                variant="outlined"
                size="small"
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={(e) => onDelete(e)}
                size="small"
                endIcon={loading && <CircularProgress size="1.5rem" />}
                disabled={deleteReason.trim() === "" || loading}
              >
                Delete
              </Button>
            </Stack>
          )}
        </Stack>
        <Collapse
          orientation="horizontal"
          in={showImdrfSelect}
          unmountOnExit
          timeout={0}
          onEntered={() => actionRef.current.updatePosition()}
          onExited={() => actionRef.current.updatePosition()}
        >
          <IMDRFComponent
            projectId={projectId}
            annexes={categoryAnnexes[category]}
            actionRef={actionRef}
            selectedCode={imdrfCode}
            setSelectedCode={setSelectedCode}
          />
        </Collapse>
      </Stack>
    </Popover>
  );
}
