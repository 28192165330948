import React, { useState } from "react";
import { updatePatientVisits } from "../../../models/patient";
import VisitsDrawer from "../../details/patients/VisitsDrawer";
import CodeListTable from "./CodeListTable";

export default function CodeList({
  codes,
  patientId,
  visits,
  updatePatient,
  loading,
  updatePatientList,
  imdrfEnabled,
}) {
  const [visitToLoad, setVisitToLoad] = useState(null);

  // here will be the logic to implement the search input
  function closeVisitsDrawer() {
    setVisitToLoad(null);
  }

  function handleVisitListUpdate(data) {
    const visitsUpdated = updatePatientVisits(visits, data);
    updatePatient({ encounters: visitsUpdated });
  }

  function onOpenVisit(visitId) {
    setVisitToLoad(visitId);
  }

  return (
    <>
      <CodeListTable
        codes={codes}
        loading={loading}
        onOpenVisit={onOpenVisit}
      />
      {visitToLoad && (
        <VisitsDrawer
          toggleDrawer={closeVisitsDrawer}
          patientId={patientId}
          visitId={visitToLoad}
          updatedList={handleVisitListUpdate}
          updatePatientList={updatePatientList}
          imdrfEnabled={imdrfEnabled}
        />
      )}
    </>
  );
}
