import {
  Breadcrumbs,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../shared/Footer";
import { RequireAuth, useFusionAuth } from "@fusionauth/react-sdk";
import { useFetch } from "../../../services/hooks/useFetch";
import { apiPrefix, deviceApiPrefix } from "../../../utils/main";

export default function ProjectDetailsSettingsPage({ projectId }) {
  const [editMode, setEditMode] = useState(false);
  const [refreshValues, setRefreshValues] = useState(false);

  const { data: validSources, fetch: getSources } = useFetch();

  const {
    data: project,
    fetch: getProject,
    update: updateProject,
  } = useFetch();

  const { update: updateProjectDatasource } = useFetch();
  const { update: updateProjectImdrfStatus } = useFetch();

  const [projectStatus, setProjectStatus] = useState(true);
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [dataSource, setDataSource] = useState("");
  const [imdrfDisabled, setImdrfDisabled] = useState("");
  const [updateReason, setUpdateReason] = useState("");

  const [displayErrors, setDisplayErrors] = useState(false);

  const { userInfo } = useFusionAuth();
  const isAdmin = userInfo?.roles?.includes("ADMIN");

  const location = useLocation();

  useEffect(() => {
    getProject(`${apiPrefix}/projects/${projectId}`);

    if (refreshValues) setRefreshValues(false);
  }, [projectId, refreshValues, getProject]);

  useEffect(() => {
    if (project) {
      if (typeof project.isActive === "boolean") {
        setProjectStatus(project.isActive);
      }

      if (typeof project.name === "string") {
        setProjectName(project.name);
      }

      if (typeof project.description === "string") {
        setProjectDescription(project.description);
      }

      let projectSettings = project.projectSettings || [];
      for (let i = 0; i < projectSettings.length; i++) {
        if (projectSettings[i].name.toLowerCase() === "datasource") {
          setDataSource(projectSettings[i].value);
        }

        if (projectSettings[i].name === "DISABLE_IMDRF") {
          setImdrfDisabled(projectSettings[i].value);
        }
      }
    }
  }, [project]);

  useEffect(() => {
    if (isAdmin) {
      getSources(`${deviceApiPrefix}/sources/`);
    }

    if (refreshValues) setRefreshValues(false);
  }, [getSources, refreshValues, isAdmin]);

  const getImdrfStatus = function (imdrfDisabled) {
    if (imdrfDisabled === "" || imdrfDisabled === "FALSE") {
      return "Enabled";
    }

    if (imdrfDisabled === "TRUE") {
      return "Disabled";
    }

    return null;
  };

  const handleUpdates = function () {
    if (projectName !== "" && updateReason !== "") {
      project.isActive = projectStatus;
      project.name = projectName;
      project.description = projectDescription;

      let sourceSettingExists = false;
      let imdrfSettingExists = false;
      let sourceSettingId = "";
      let imdrfSettingId = "";

      for (let i = 0; i < project.projectSettings.length; i++) {
        if (project.projectSettings[i].name.toLowerCase() === "datasource") {
          sourceSettingId = project.projectSettings[i].id;
          sourceSettingExists = true;
        }

        if (project.projectSettings[i].name === "DISABLE_IMDRF") {
          imdrfSettingId = project.projectSettings[i].id;
          imdrfSettingExists = true;
        }
      }

      updateProject(
        `${apiPrefix}/projects/${projectId}`,
        {
          reason: updateReason,
          project: project,
        },
        "PUT"
      )
        .then(() => {
          if (sourceSettingExists) {
            updateProjectDatasource(
              `${apiPrefix}/projects/${projectId}/settings/${sourceSettingId}`,
              {
                name: "DATASOURCE",
                value: dataSource,
              },
              "PUT"
            );
          } else {
            updateProjectDatasource(
              `${apiPrefix}/projects/${projectId}/settings`,
              {
                name: "DATASOURCE",
                value: dataSource,
              },
              "POST"
            );
          }

          if (imdrfSettingExists) {
            updateProjectImdrfStatus(
              `${apiPrefix}/projects/${projectId}/settings/${imdrfSettingId}`,
              {
                name: "DISABLE_IMDRF",
                value: imdrfDisabled,
              },
              "PUT"
            );
          } else {
            updateProjectImdrfStatus(
              `${apiPrefix}/projects/${projectId}/settings`,
              {
                name: "DISABLE_IMDRF",
                value: imdrfDisabled,
              },
              "POST"
            );
          }
        })
        .then(() => {
          setRefreshValues(true);
          setEditMode(false);
          setDisplayErrors(false);
        });
    } else {
      setDisplayErrors(true);
    }
  };

  return (
    <div>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <br />
        <div className="company-settings-item">
          <Grid item>
            <Breadcrumbs>
              {location.state?.previousPath ? (
                <Link
                  underline="hover"
                  color="inherit"
                  to={location.state.previousPath}
                >
                  {project.name}
                </Link>
              ) : (
                <Link
                  underline="hover"
                  color="inherit"
                  to={`/details?projectId=${projectId}`}
                >
                  {project.name}
                </Link>
              )}
              <Link
                underline="hover"
                color="inherit"
                to={`/details?projectId=${projectId}`}
              >
                Project Settings
              </Link>
              <Typography>Project Details</Typography>
            </Breadcrumbs>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="h4">Project Details</Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body2">
              The name and description for this project. This information can be
              modified at any time after the project is created.
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body2">* Required fields</Typography>
          </Grid>
          <br />
          <RequireAuth withRole={"ADMIN"}>
            <Grid item>
              {editMode ? (
                <>
                  <InputLabel shrink htmlFor="select-project-status" required>
                    Project Status
                  </InputLabel>
                  <Select
                    required
                    inputProps={{ id: "select-project-status" }}
                    sx={{ width: "50ch" }}
                    value={projectStatus}
                    onChange={(event) => {
                      setProjectStatus(event.target.value);
                    }}
                    readOnly={!editMode}
                  >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                  </Select>
                </>
              ) : (
                <>
                  <Typography variant="caption">Project Status</Typography>
                  <Typography variant="body1" sx={{ width: "50ch" }}>
                    {projectStatus ? "Active" : "Inactive"}
                  </Typography>
                </>
              )}
            </Grid>
          </RequireAuth>
          <br />
          <Grid item>
            {editMode ? (
              <TextField
                required
                label={"Project Name"}
                sx={{ width: "50ch" }}
                value={projectName}
                error={displayErrors && projectName === ""}
                helperText={
                  displayErrors && projectName === ""
                    ? "This field is required"
                    : ""
                }
                onChange={(event) => {
                  setProjectName(event.target.value);
                }}
                InputProps={{
                  readOnly: !editMode,
                }}
              ></TextField>
            ) : (
              <>
                <Typography variant="caption">Project Name *</Typography>
                <Typography variant="body1" sx={{ width: "50ch" }}>
                  {projectName}
                </Typography>
              </>
            )}
          </Grid>
          <br />
          <Grid item>
            {editMode ? (
              <TextField
                multiline
                label={"Project Description"}
                sx={{ width: "50ch" }}
                value={projectDescription}
                onChange={(event) => {
                  setProjectDescription(event.target.value);
                }}
                InputProps={{
                  readOnly: !editMode,
                }}
              ></TextField>
            ) : (
              <>
                <Typography variant="caption">Project Description</Typography>
                <Typography variant="body1" sx={{ width: "50ch" }}>
                  {projectDescription}
                </Typography>
              </>
            )}
          </Grid>
          <br />
          {isAdmin && (
            <Grid item>
              {editMode ? (
                <>
                  <InputLabel shrink htmlFor="select-source">
                    Data Source
                  </InputLabel>
                  <Select
                    label={"Data Source"}
                    sx={{ width: "50ch" }}
                    value={dataSource}
                    onChange={(event) => {
                      setDataSource(event.target.value);
                    }}
                    inputProps={{ id: "select-source" }}
                    readOnly={!editMode}
                  >
                    <MenuItem value="">None</MenuItem>
                    {validSources.map((value) => {
                      return (
                        <MenuItem key={value.name} value={value.name}>
                          {value.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </>
              ) : (
                <>
                  <Typography variant="caption">Data Source</Typography>
                  <Typography variant="body1" sx={{ width: "50ch" }}>
                    {dataSource === "" ? "None" : dataSource}
                  </Typography>
                </>
              )}
            </Grid>
          )}
          <br />
          <Grid item>
            {editMode ? (
              <>
                <InputLabel shrink htmlFor="select-imdrf">
                  IMDRF Status
                </InputLabel>
                <Select
                  label="IMDRF Status"
                  sx={{ width: "50ch" }}
                  value={imdrfDisabled === "" ? "FALSE" : imdrfDisabled}
                  onChange={(event) => {
                    setImdrfDisabled(event.target.value);
                  }}
                  inputProps={{ id: "select-imdrf" }}
                  readOnly={!editMode}
                >
                  <MenuItem value="FALSE">Enabled</MenuItem>
                  <MenuItem value="TRUE">Disabled</MenuItem>
                </Select>
              </>
            ) : (
              <>
                <Typography variant="caption">IMDRF Status</Typography>
                <Typography variant="body1" sx={{ width: "50ch" }}>
                  {getImdrfStatus(imdrfDisabled)}
                </Typography>
              </>
            )}
          </Grid>
          {editMode && (
            <div>
              <br />
              <Grid item>
                <TextField
                  required
                  label="Note/Reason for Updating the Project Details"
                  sx={{ width: "50ch" }}
                  value={updateReason}
                  error={displayErrors && updateReason === ""}
                  helperText={
                    displayErrors && updateReason === ""
                      ? "This field is required"
                      : ""
                  }
                  onChange={(event) => {
                    setUpdateReason(event.target.value);
                  }}
                ></TextField>
              </Grid>
            </div>
          )}
          <br />
          <Grid container item justifyContent={"right"}>
            {(editMode && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEditMode(false);
                    setRefreshValues(true);
                    setDisplayErrors(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="update-button"
                  sx={{ marginLeft: "5px" }}
                  onClick={handleUpdates}
                >
                  Update
                </Button>
              </>
            )) || (
              <Button variant="contained" onClick={() => setEditMode(true)}>
                Edit
              </Button>
            )}
          </Grid>
          <br />
          <Footer />
        </div>
      </Grid>
    </div>
  );
}
