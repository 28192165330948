import React, { useState, useCallback, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { cyan, deepPurple, red } from "@mui/material/colors";
import {
  Link,
  Card,
  CardContent,
  Typography,
  Chip,
  Tooltip,
  Stack,
} from "@mui/material";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import CategorizeModal from "./categorize/CategorizeModal";
import CategorizeModalV2 from "./categorize/CategorizeModal";
import PatientCardSkeleton from "./skeletons/PatientCardSkeleton";
import theme from "../../../styles/theme";
import StructuredDataExpandTable from "../../shared/StructuredDataExpandTable";
import { useFetch } from "../../../services/hooks/useFetch";
import { projectsPrefix } from "../../../services/ProjectsServices";
import { CurrentPatient } from "../ProjectDetails";
import { getImdrfEnabled } from "./categorize/CategorizeUtils";

const one_day = 1000 * 60 * 60 * 24;

export default function PatientCard({
  patient,
  patientLoading,
  updatePatientList,
}) {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const [isOpen, setIsOpen] = useState(false);
  const [openProblems, setOpenProblems] = useState(false);
  const [category, setCategory] = useState({
    category: "",
    mark: "",
  });

  const {
    isProblemsUpdated,
    setIsProblemsUpdated,
    patientProblems,
    setPatientProblems,
    projectSettings,
  } = useContext(CurrentPatient);
  const imdrfEnabled = getImdrfEnabled(projectSettings);

  const { isLoading, data: problemsData, fetch: fetchProblems } = useFetch();

  function handlePopOver(e, id, subCategory, reason, adverseEventId) {
    setIsOpen(true);
    if (
      e.currentTarget.value !== "Performance" &&
      e.currentTarget.value !== "Clinical Benefit"
    ) {
      setCategory({
        adverseEventId: adverseEventId,
        category: "Adverse Events",
        id: id,
        mark: e.currentTarget,
        reason: reason,
        subcategory: subCategory,
      });
    } else {
      setCategory({
        category: e.currentTarget.value,
        id: id,
        mark: e.currentTarget,
        reason: reason,
        subcategory: subCategory,
      });
    }
  }

  function handlePopOverClose() {
    setIsOpen(false);
  }

  const textChange = (status) => {
    return status ? "Success" : "Failure";
  };

  const handleProblemsDataLoad = useCallback(() => {
    setOpenProblems(!openProblems);
  }, [openProblems]);

  useEffect(() => {
    if (openProblems && !isProblemsUpdated) {
      fetchProblems(
        `${projectsPrefix}/${projectId}/patients/${patient.id}/problems`
      ).then((_) => {
        setPatientProblems(problemsData);
        setIsProblemsUpdated(true);
      });
    }
  }, [
    fetchProblems,
    openProblems,
    patient.id,
    projectId,
    patientProblems,
    setPatientProblems,
    problemsData,
    isProblemsUpdated,
    setIsProblemsUpdated,
  ]);

  const reasonSearcher = (reasons, reasonType) => {
    const reasonValue = reasons.find(
      (reason) => reason.reasonType === reasonType
    );
    return reasonValue !== null ? reasonValue : { id: "", reason: "" };
  };

  if (!patient || !patient.id || patientLoading) {
    return <PatientCardSkeleton />;
  }

  const problemDataFields = [
    { field: "problemName", headerName: "Problem", width: 250 },
    { field: "yearNoted", headerName: "Year Noted", width: 100 },
    { field: "diffDays", headerName: "Days Pre/Post", width: 115 },
    { field: "patientAgeAtEvent", headerName: "Age", width: 100 },
    { field: "problemStatus", headerName: "Status", width: 100 },
  ];

  return (
    <Card className={"patient-card"}>
      <CardContent align="left" sx={{ padding: 0 }}>
        <Stack className={"content"} flexDirection={"column"}>
          <Typography className={"title"} variant="subtitle1">
            Patient Info
          </Typography>
          <div className="column">
            <div className="row">
              <Typography variant="body2">Age</Typography>
              <Typography className={"bold-weight"} variant="body2">
                {patient.age}
              </Typography>
            </div>
            <div className="row">
              <Typography variant="body2">Gender</Typography>
              <Typography className={"bold-weight"} variant="body2">
                {patient.gender}
              </Typography>
            </div>
            {patient.deathFlag && (
              <div className="row">
                <Typography variant="body2">Deceased</Typography>
                {patient.deathFlag ? (
                  <Typography className={"bold-weight"} variant="body2">
                    Yes,{" "}
                    {Math.round(
                      (new Date(patient.deathDate).getTime() -
                        new Date(patient.procedureDate).getTime()) /
                        one_day
                    )}{" "}
                    days post procedure
                  </Typography>
                ) : (
                  <Typography className={"bold-weight"} variant="body2">
                    Yes, date not provided
                  </Typography>
                )}
              </div>
            )}
            <div className="column">
              {((patient.adverseEvents && patient.adverseEvents.length > 0) ||
                patient.clinicalBenefit ||
                patient.performance) && (
                <Typography className={"categorization-title"} variant="h6">
                  Categorization
                </Typography>
              )}
              {patient.adverseEvents &&
                patient.adverseEvents.length > 0 &&
                patient.adverseEvents.map((adverseEvent) => {
                  return (
                    <div className="row" key={adverseEvent.id}>
                      <Chip
                        size="small"
                        sx={{ bgcolor: red[50], color: red[800] }}
                        label={
                          adverseEvent.attributeValue + " (Adverse Events)"
                        }
                        variant="body2"
                        icon={
                          <WarningAmberIcon
                            fontSize="small"
                            style={{ color: red[700] }}
                          />
                        }
                        onDelete={() => {}}
                        deleteIcon={
                          <Tooltip title={adverseEvent.reason}>
                            <StickyNote2OutlinedIcon
                              fontSize="small"
                              style={{ color: theme.palette.action.active }}
                            />
                          </Tooltip>
                        }
                      ></Chip>
                      <Link
                        component={"button"}
                        variant={"subtitle2"}
                        value="Adverse Events"
                        onClick={(e) =>
                          handlePopOver(
                            e,
                            "",
                            adverseEvent.attributeValue,
                            adverseEvent.reason,
                            adverseEvent.id
                          )
                        }
                      >
                        Edit
                      </Link>
                    </div>
                  );
                })}
              {patient.clinicalBenefit && (
                <div className="row">
                  <Chip
                    size="small"
                    sx={{
                      bgcolor: deepPurple[50],
                      color: deepPurple[700],
                    }}
                    label={
                      textChange(patient.clinicalBenefitSuccess) +
                      " (Clinical Benefit)"
                    }
                    variant="body2"
                    icon={
                      <MedicalServicesOutlinedIcon
                        fontSize="small"
                        style={{ color: deepPurple[600] }}
                      />
                    }
                    onDelete={() => {}}
                    deleteIcon={
                      <Tooltip
                        title={
                          reasonSearcher(patient.reasons, "clinicalBenefit")
                            .reason
                        }
                      >
                        <StickyNote2OutlinedIcon
                          fontSize="small"
                          style={{ color: theme.palette.action.active }}
                        />
                      </Tooltip>
                    }
                  ></Chip>
                  <Link
                    component={"button"}
                    variant={"subtitle2"}
                    value="Clinical Benefit"
                    onClick={(e) =>
                      handlePopOver(
                        e,
                        reasonSearcher(patient.reasons, "clinicalBenefit").id,
                        textChange(patient.clinicalBenefitSuccess),
                        reasonSearcher(patient.reasons, "clinicalBenefit")
                          .reason
                      )
                    }
                  >
                    Edit
                  </Link>
                </div>
              )}
              {patient.performance && (
                <div className="row">
                  <Chip
                    size="small"
                    sx={{ bgcolor: cyan[50], color: cyan[700] }}
                    label={
                      textChange(patient.performanceSuccess) + " (Performance)"
                    }
                    variant="body2"
                    icon={
                      <TrendingUpOutlinedIcon
                        fontSize="small"
                        style={{ color: cyan[600] }}
                      />
                    }
                    onDelete={() => {}}
                    deleteIcon={
                      <Tooltip
                        title={
                          reasonSearcher(patient.reasons, "performance").reason
                        }
                      >
                        <StickyNote2OutlinedIcon
                          fontSize="small"
                          style={{ color: theme.palette.action.active }}
                        />
                      </Tooltip>
                    }
                  ></Chip>
                  <Link
                    value={"Performance"}
                    component={"button"}
                    variant={"subtitle2"}
                    onClick={(e) =>
                      handlePopOver(
                        e,
                        reasonSearcher(patient.reasons, "performance").id,
                        textChange(patient.performanceSuccess),
                        reasonSearcher(patient.reasons, "performance").reason
                      )
                    }
                  >
                    Edit
                  </Link>
                </div>
              )}
            </div>
          </div>
        </Stack>
        <div className="column-for-structured-data">
          <Typography className={"patient-row-title"} variant="h6">
            Patient Data
          </Typography>
          <StructuredDataExpandTable
            loading={isLoading}
            title={"Problems"}
            count={patient?.problemsCount} // change to actual count from data
            handleChange={handleProblemsDataLoad}
            fields={problemDataFields}
            rows={problemsData}
          />
        </div>
      </CardContent>
      {isOpen &&
        (imdrfEnabled ? (
          <CategorizeModalV2
            anchorEl={category.mark}
            onClose={handlePopOverClose}
            open={true}
            patientId={patient.id}
            selected={category}
            updateList={updatePatientList}
            patientNumber={patient.patientNumber}
          />
        ) : (
          <CategorizeModal
            anchorEl={category.mark}
            onClose={handlePopOverClose}
            open={true}
            patientId={patient.id}
            selected={category}
            updateList={updatePatientList}
          />
        ))}
    </Card>
  );
}
