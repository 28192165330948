import { compareTwoValues, convertDate } from "../utils/main";

export const emptyEncounter = {
  arriveDate: "",
  admitDate: "",
  dischargeDate: "",
  diagnosisCodes: [],
  id: "",
  notes: [],
  procedureCodes: [],
  encounterType: "",
};

export const emptyPatient = {
  id: null,
  age: 0,
  sex: null,
  procedureDate: new Date("0001-01-01"),
  targetLocation: null,
  indicationForUse: null,
  deviceDeficient: null,
  procedureCodes: [],
  diagnosisCodes: [],
  encounters: [],
  patientSummary: {
    id: 0,
    age: 0,
    sex: "Undefined",
    procedureDate: new Date("0001-01-01"),
  },
  reasons: [],
};

export function formatPatient(rawPatient) {
  const { stringToDate } = convertDate;
  const procedureDate = stringToDate(rawPatient.procedureDate);
  const patientSummary = stringToDate(rawPatient.patientSummary.procedureDate);
  return { ...rawPatient, procedureDate, patientSummary };
}

export function formatEncounter(encounter) {
  const { stringToDate } = convertDate;
  const arriveDate = stringToDate(encounter.arriveDate);
  return { ...encounter, arriveDate };
}

export function patientActivityLogs(logs) {
  const { stringToDate } = convertDate;
  return logs.map((log) => {
    return {
      ...log,
      createdAt: stringToDate(log.createdAt),
    };
  });
}

// TO-DO: this logic doesn't work assuming newVisitData is an array of visits versus a single visit... Update its definition or usage
export function updatePatientVisits(visits, newVisitData) {
  return visits.reduce((prev, curr) => {
    if (curr.id === newVisitData.id) {
      return prev.concat({ ...curr, ...newVisitData });
    }
    return prev.concat(curr);
  }, []);
}

export const visitsSortByEnum = {
  title: "diffDays",
  priority: "priority",
  related: "isRelated",
};

export function sortPatientVisits(sort, sortBy, visits) {
  if (sortBy === visitsSortByEnum.related) {
    return visits.sort((a) => {
      if (sort === "asc") {
        return a.isRelated || a.isRelated === null ? -1 : 1;
      }
      return a.isRelated || a.isRelated === null ? 1 : -1;
    });
  }
  return visits.sort((a, b) => {
    if (sort === "asc") {
      return compareTwoValues(a[sortBy], b[sortBy]);
    }
    return compareTwoValues(b[sortBy], a[sortBy]);
  });
}
export function sortEncounterCodes(sort, sortBy, codes) {
  const dataSortedAsc = codes.sort((a, b) => {
    return compareTwoValues(a[sortBy], b[sortBy]);
  });
  if (sort === "asc") {
    return dataSortedAsc;
  }
  return dataSortedAsc.reverse();
}
