import React from "react";
import {
  AditionalInformationIcon,
  ComplicationIcon,
  ClinicalBenefitIcon,
  PatientExcludedIcon,
  PatientIncludedIcon,
  PatientUndeterminedIcon,
  PerformanceIcon,
  ProjectDetailsIcon,
  ProjectFiltersIcon,
  VisitRelationship,
} from "../../customicons/CustomIcons";

const projectFiltersIcon = [
  "Demographic Filters",
  "Diagnosis Code Filters",
  "Procedure Code Filters",
];
export default function PatientsLogIcons({ log }) {
  if (!log.type) {
    return <DefaultComponent />;
  }
  if (log.type.category === "Patient Categorization") {
    if (!log.subtype) {
      return <DefaultComponent />;
    }
    if (log.subtype.description === "Clinical Benefit") {
      return <ClinicalBenefitIcon type={log.type.action} />;
    }
    if (log.subtype.description === "Performance") {
      return <PerformanceIcon type={log.type.action} />;
    }
    if (
      log.subtype.description === "Adverse Event" ||
      log.subtype.description === "Complication"
    ) {
      return <ComplicationIcon type={log.type.action} />;
    }
  }
  if (log.type.category === "Patient Include/Exclude") {
    if (log.value === "Included") {
      return <PatientIncludedIcon />;
    }
    if (log.value === "Excluded") {
      return <PatientExcludedIcon />;
    }
    if (log.value === "Undetermined") {
      return <PatientUndeterminedIcon />;
    }
  }
  if (log.type.category === "Additional Information") {
    return <AditionalInformationIcon type={log.type.action} />;
  }
  if (log.type.category === "Visit Related/Not Related") {
    return <VisitRelationship type={log.type.action} />;
  }
  if (projectFiltersIcon.includes(log.type.category)) {
    return <ProjectFiltersIcon type={log.type.action} />;
  }
  if (log.type.category === "Project Details") {
    return <ProjectDetailsIcon type={log.type.action} />;
  }
  return <DefaultComponent />;
}
function DefaultComponent() {
  return <div></div>;
}
