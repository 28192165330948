import {
  Autocomplete,
  Breadcrumbs,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFusionAuth } from "@fusionauth/react-sdk";
import Footer from "../../shared/Footer";
import { useFetch } from "../../../services/hooks/useFetch";
import { apiPrefix, deviceApiPrefix } from "../../../utils/main";

export default function DeviceDetailsSettings({ projectId }) {
  const [editMode, setEditMode] = useState(false);

  const { data: devicesFromSelection, fetch: fetchDevicesFromSelection } =
    useFetch();
  const [devices, setDevices] = useState(new Map());
  const {
    data: project,
    fetch: fetchProject,
    update: updateProject,
  } = useFetch();
  const [projectDevices, setProjectDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState(new Map());

  const { data: deviceManufacturers, fetch: getDeviceManufacturers } =
    useFetch();
  const [selectedManufacturer, setSelectedManufacturer] = useState({});
  const [currentDMSearch, setCurrentDMSearch] = useState("");

  const { data: gmdns, fetch: fetchGmdns } = useFetch();
  const [gmdnIndex, setGmdnIndex] = useState(-1);

  const [updateDetails, setUpdateDetails] = useState("");

  const [displayErrors, setDisplayErrors] = useState(false);

  const [refreshRequired, setRefreshRequired] = useState(false);

  const { userInfo } = useFusionAuth();
  const isWorkshop = userInfo?.roles?.includes("WORKSHOP");
  const isAdmin = userInfo?.roles?.includes("ADMIN");

  const location = useLocation();
  const isUser = userInfo?.roles?.includes("USER");

  useEffect(() => {
    if (isWorkshop) {
      if (currentDMSearch !== "") {
        getDeviceManufacturers(
          `${deviceApiPrefix}/devices/manufacturers?search=~${currentDMSearch}~`
        );
      } else {
        getDeviceManufacturers(
          `${deviceApiPrefix}/devices/manufacturers?search=~~`
        );
      }
    } else {
      getDeviceManufacturers(
        `${deviceApiPrefix}/devices/manufacturers/company/`
      );
    }
  }, [isWorkshop, getDeviceManufacturers, currentDMSearch]);

  const handleChangeManufacturers = function (event, value) {
    if (value === null) {
      setSelectedManufacturer({});
      setCurrentDMSearch("");
    } else {
      setSelectedManufacturer(value);
      setCurrentDMSearch(value.name);
    }
  };

  const getNameOrEmpty = function (option) {
    if (option.name) {
      return option.name;
    } else {
      return "";
    }
  };

  const isOptionEqualToValueManufacturer = function (option, value) {
    if (value === null) {
      return true;
    }

    if (option.id && value.id) {
      return option.id === value.id;
    }

    return false;
  };

  const handleDMSearch = function (event) {
    if (event === null) {
      setCurrentDMSearch("");
    } else {
      setCurrentDMSearch(event.target.value);
    }
  };

  useEffect(() => {
    fetchProject(`${apiPrefix}/projects/${projectId}`);

    if (refreshRequired) setRefreshRequired(false);
  }, [fetchProject, projectId, refreshRequired]);

  useEffect(() => {
    if (project) {
      setProjectDevices(project.devices);
    } else {
      setProjectDevices([]);
    }
  }, [project]);

  useEffect(() => {
    let initialSelectedDevices = new Map();

    if (projectDevices) {
      projectDevices.forEach((value) => {
        initialSelectedDevices.set(value.id, value);
      });
    }

    setSelectedDevices(initialSelectedDevices);
  }, [projectDevices]);

  useEffect(() => {
    if (isWorkshop) {
      getDeviceManufacturers(
        `${deviceApiPrefix}/devices/manufacturers?search=~~`
      );
    } else {
      getDeviceManufacturers(
        `${deviceApiPrefix}/devices/manufacturers/company/`
      );
    }

    if (refreshRequired) setRefreshRequired(false);
  }, [isWorkshop, getDeviceManufacturers, refreshRequired]);

  useEffect(() => {
    if (isWorkshop && selectedManufacturer?.id) {
      fetchGmdns(
        `${deviceApiPrefix}/devices/manufacturers/${selectedManufacturer.id}/categories`
      );
    } else {
      fetchGmdns(`${deviceApiPrefix}/categories/`);
    }

    if (refreshRequired) setRefreshRequired(false);
  }, [fetchGmdns, refreshRequired, isWorkshop, selectedManufacturer?.id]);

  useEffect(() => {
    if (!isWorkshop) {
      if (gmdns && gmdns[gmdnIndex]) {
        fetchDevicesFromSelection(
          `${deviceApiPrefix}/categories/${gmdns[gmdnIndex].id}/devices`
        );
      }
    } else {
      if (selectedManufacturer.id && gmdns && gmdns[gmdnIndex]) {
        fetchDevicesFromSelection(
          `${deviceApiPrefix}/devices/manufacturers/${selectedManufacturer.id}/categories/${gmdns[gmdnIndex].id}`
        );
      }
    }

    if (refreshRequired) setRefreshRequired(false);
  }, [
    selectedManufacturer,
    fetchDevicesFromSelection,
    gmdnIndex,
    gmdns,
    isWorkshop,
    refreshRequired,
  ]);

  useEffect(() => {
    let newDevices = new Map();

    if (devicesFromSelection) {
      devicesFromSelection.forEach((value) => {
        newDevices.set(value.id, value);
      });
    }

    if (projectDevices) {
      projectDevices.forEach((device) => {
        newDevices.set(device.id, device);
      });
    }

    setDevices(newDevices);
  }, [devicesFromSelection, projectDevices, setDevices]);

  useEffect(() => {
    if (projectDevices && projectDevices.length > 0) {
      setSelectedManufacturer(projectDevices[0].deviceManufacturer);
      setCurrentDMSearch(projectDevices[0].deviceManufacturer.name);
    }

    if (refreshRequired) setRefreshRequired(false);
  }, [projectDevices, isWorkshop, refreshRequired]);

  useEffect(() => {
    if (project.deviceGmdn) {
      let i = gmdns.findIndex((value) => {
        return value.id === project.deviceGmdn.id;
      });

      setGmdnIndex(i);
    }
  }, [project.deviceGmdn, gmdns]);

  return (
    <div>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <br />
        <div className="company-settings-item">
          <Grid item>
            <Breadcrumbs>
              {location.state?.previousPath ? (
                <Link
                  underline="hover"
                  color="inherit"
                  to={location.state.previousPath}
                >
                  {project.name}
                </Link>
              ) : (
                <Link
                  underline="hover"
                  color="inherit"
                  to={`/details?projectId=${projectId}`}
                >
                  {project.name}
                </Link>
              )}
              <Link
                underline="hover"
                color="inherit"
                to={`/details?projectId=${projectId}`}
              >
                Project Settings
              </Link>
              <Typography>Device Details</Typography>
            </Breadcrumbs>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="h4">Device Details</Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body2">
              Information about the medical device(s) included in this project.
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body2">* Required fields</Typography>
          </Grid>
          <br />
          {!isUser && (
            <>
              <Grid item>
                {editMode && isWorkshop ? (
                  <FormControl
                    error={displayErrors && !selectedManufacturer.id}
                    sx={{ width: "50ch" }}
                  >
                    <Autocomplete
                      filterOptions={(x) => x}
                      disablePortal
                      value={
                        selectedManufacturer.id ? selectedManufacturer : null
                      }
                      options={deviceManufacturers}
                      getOptionLabel={getNameOrEmpty}
                      onChange={handleChangeManufacturers}
                      isOptionEqualToValue={isOptionEqualToValueManufacturer}
                      inputValue={currentDMSearch}
                      onInputChange={handleDMSearch}
                      renderInput={(params) => (
                        <TextField {...params} label="Manufacturer *" />
                      )}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                    />
                    <FormHelperText>
                      {displayErrors && !selectedManufacturer.id
                        ? "This field is required"
                        : ""}
                    </FormHelperText>
                  </FormControl>
                ) : (
                  <>
                    <Typography variant="caption">Manufacturer *</Typography>
                    <Typography variant="body1">
                      {selectedManufacturer.id
                        ? selectedManufacturer.name
                        : "None"}
                    </Typography>
                  </>
                )}
              </Grid>
              <br />
              <Grid item>
                {editMode ? (
                  <FormControl
                    error={displayErrors && gmdnIndex === -1}
                    sx={{ width: "50ch" }}
                  >
                    <InputLabel shrink htmlFor="select-categories" required>
                      GMDN Category
                    </InputLabel>
                    <Select
                      value={gmdnIndex}
                      label={"GMDN Category"}
                      readOnly={!editMode}
                      onChange={(event) => {
                        setGmdnIndex(event.target.value);
                      }}
                      inputProps={{ id: "select-categories" }}
                    >
                      <MenuItem value={-1}>N.A.</MenuItem>
                      {gmdns.map((json, index) => {
                        return (
                          <MenuItem key={index} value={index}>
                            {json.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {displayErrors && gmdnIndex === -1
                        ? "This field is required"
                        : ""}
                    </FormHelperText>
                  </FormControl>
                ) : (
                  <>
                    <Typography variant="caption">GMDN Category *</Typography>
                    <Typography variant="body1">
                      {gmdns[gmdnIndex] ? gmdns[gmdnIndex].name : "None"}
                    </Typography>
                  </>
                )}
              </Grid>
            </>
          )}
          <br />
          <Grid item>
            {editMode ? (
              <FormControl
                error={displayErrors && selectedDevices.length === 0}
              >
                <InputLabel shrink htmlFor="select-devices" required>
                  Device
                </InputLabel>
                <Autocomplete
                  filterSelectedOptions
                  multiple
                  freeSolo
                  id={"select-devices"}
                  readOnly={!editMode}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  options={Array.from(devices.values())}
                  value={Array.from(selectedDevices.values())}
                  getOptionLabel={(option) => option.description}
                  getOptionKey={(option) => option.id}
                  data-testid="DeviceDetailSettings-autocompletedevice"
                  onChange={(event, value) => {
                    setSelectedDevices(value);
                  }}
                  ListboxProps={{
                    "data-testid": "DeviceDetailSettings-devicesPopper",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="medium"
                      variant="outlined"
                      label={"Device Description"}
                      required
                      inputProps={{
                        ...params.inputProps,
                        "data-testid": "DeviceDetailsSettings-devices",
                      }}
                    />
                  )}
                  sx={{ width: "50ch" }}
                />
                <FormHelperText>
                  {displayErrors && selectedDevices.length === 0
                    ? "This field is required"
                    : ""}
                </FormHelperText>
              </FormControl>
            ) : (
              <>
                <Typography variant="caption">Device *</Typography>
                <Typography variant="body1">
                  {Array.from(selectedDevices.values())
                    .map((value) => value.description)
                    .toString()
                    .replaceAll(",", ", ")}
                </Typography>
              </>
            )}
          </Grid>
          <br />
          {editMode && (
            <>
              <br />
              <Grid item>
                <TextField
                  required
                  variant="outlined"
                  label="Note/Reason for Updating the Device Details"
                  className="settings-text-input"
                  sx={{ width: "50ch" }}
                  value={updateDetails}
                  error={displayErrors && updateDetails === ""}
                  helperText={
                    displayErrors && updateDetails === ""
                      ? "This field is required"
                      : ""
                  }
                  onChange={(event) => {
                    setUpdateDetails(event.target.value);
                  }}
                />
              </Grid>
            </>
          )}
          <br />
          <Grid container item justifyContent={"right"}>
            {(editMode && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setUpdateDetails("");
                    setSelectedDevices(projectDevices);
                    setEditMode(false);
                    setDisplayErrors(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="update-button"
                  sx={{ marginLeft: "5px" }}
                  onClick={() => {
                    if (isAdmin) {
                      if (
                        selectedManufacturer.id === null ||
                        gmdnIndex === -1 ||
                        selectedDevices.length === 0 ||
                        updateDetails === ""
                      ) {
                        setDisplayErrors(true);
                        return;
                      } else if (
                        projectDevices &&
                        selectedDevices &&
                        JSON.stringify(projectDevices) !==
                          JSON.stringify(selectedDevices)
                      ) {
                        project.devices = selectedDevices;
                        updateProject(
                          `${apiPrefix}/projects/${projectId}`,
                          {
                            reason: updateDetails,
                            project: project,
                          },
                          "PUT"
                        );
                      }

                      setDisplayErrors(false);
                      setEditMode(false);
                    }
                  }}
                >
                  Update
                </Button>
              </>
            )) ||
              (isAdmin && (
                <Button variant="contained" onClick={() => setEditMode(true)}>
                  Edit
                </Button>
              ))}
          </Grid>
          <br />
          <Grid item>
            <Footer />
          </Grid>
          <br />
        </div>
      </Grid>
    </div>
  );
}
